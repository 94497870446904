import React ,{useEffect,useState} from "react";
import { Link } from "react-router-dom";
import './UseCases.css';
import Header from "../Components/Header";
import { Section8 } from "./Home";
import Footer from "../Components/Footer";
import { Preloader } from "./About";


import ogc from "../Images/uc-pic1.png";
import nab from "../Images/uc-pic2.png";
import gfsl from "../Images/uc-pic3.png";
import rp from "../Images/uc-pic4.png";

import { scrollToTop } from "./Home";


import CaseStudy1 from '../PDF Files/1-CaseStudy.pdf';
import CaseStudy2 from '../PDF Files/2-CaseStudy.pdf';
import CaseStudy3 from '../PDF Files/3-CaseStudy.pdf';
import CaseStudy4 from '../PDF Files/4-CaseStudy.pdf';

const Spacer = () =>{
    return(
        <>
            <div class="spacer">
                &nbsp;
            </div>
        </>
    )
}

const UCSection1 = () => {
    return(
        <>
            <section className="uc1">
                <div className="uc1-data">
                    <div className="uc1-data-left">
                    <h1>Explore Our Case Studies</h1>
                    <p>We have worked on multiple projects across industries, helping our clients  focus on what matters most. Read more to see how we can support you to process large volumes of data to generate better insights.</p>
                    <Link to={"/contact-us/"} onClick={scrollToTop}><button className="a1-button">Request a Demo</button></Link>
                    </div>
                </div>
            </section>
        </>
    )
}

const UCSection2 = () =>{
    return(
        <>
            <section className="uc2">
                <div className="dot-square-blue" style={{position:'absolute',height:'100px',width:'100px',left:'-45px',transform:'rotateZ(180deg)'}}></div>
                <div className="dot-square-blue" style={{position:'absolute',height:'100px',width:'100px',top:'255%',right:'0'}}></div>
                <div className="ogc">
                    <div className="ogc-left">
                        <h2>Case Study</h2>
                        <Link to={CaseStudy1} style={{color:'black'}} target="_blank" rel="noopener noreferrer"><h1>Oil and Gas Company</h1></Link>
                        <p>A top U.S. based Oil & Gas company uses AIML Data for integration of multiple transaction data platforms</p>
                        <div className="ogc-left-mid">
                            <Link to={CaseStudy1} target="_blank" rel="noopener noreferrer"><p className="ogc-left-mid-link">Read More</p></Link>
                        </div>
                        <div className="ogc-left-down">
                            <div className="ogc-left-down-problem">
                                <h1>Problem</h1>
                                <p>Our client, who had acquired two competitors providing Oil & Gas M&A, wanted to move the merged dataset ( over 300,000 transactions across different Oil & Gas verticals) into a new SAAS based platform.</p>
                            </div>
                            <div className="pulses"></div>
                            <div className="ogc-left-down-solution">
                                <h1>Solution</h1>
                                <p>AIML Data matches 100 attributes in both the databases, identifies key attributes, extracts details and validates data in both the databases. The approved transactions with validated data is fed into the new platform with the client defined attributes</p>
                            </div>
                            <div className="pulses"></div>
                            <div className="ogc-left-down-result">
                                <h1>Results</h1>
                                <p>Data redundancy removed from the two overlapping databases. Faster and reliable output in the new platform with better quality dataset</p>
                            </div>
                        </div>
                    </div>
                    <div className="ogc-right">
                        <img src={ogc} alt="VVVV"/>
                    </div>
                </div>
                <div className="nab">
                    <div className="ogc-left">
                        <h2>Case Study</h2>
                        <Link to={CaseStudy2} style={{color:'black'}} target="_blank" rel="noopener noreferrer"><h1>North American Bank</h1></Link>
                        <p>In just weeks, a leading bank increases their throughput by 15 folds by using AIML Data’s intelligent automation for invoice processing and delivered tangible business value</p>
                        <div className="ogc-left-mid">
                            <Link to={CaseStudy2} target="_blank" rel="noopener noreferrer"><p className="ogc-left-mid-link">Read More</p></Link>
                        </div>
                        <div className="ogc-left-down">
                            <div className="ogc-left-down-problem">
                                <h1>Problem</h1>
                                <p>Our client’s staff manually reconciled invoices reading 2000+ invoices daily in 36 different formats. Any change in the input files impacted error rate and throughput. Redundant processes impacted real time availability of data.</p>
                            </div>
                            <div className="pulses"></div>
                            <div className="ogc-left-down-solution">
                                <h1>Solution</h1>
                                <p>Now, AIML Data’s modular AI extracts details from invoices, transforms them in a machine readable format and annotates key information based client account criteria. Algorithm approved data is fed to a custom AIML Ledger system to reconcile the payments.</p>
                            </div>
                            <div className="pulses"></div>
                            <div className="ogc-left-down-result">
                                <h1>Results</h1>
                                <p>Error rate brought down to 0% in 3 iterations. Throughput increased 15 fold. Analyst utilization reduced by 65% per month. Better customer experience and satisfaction.</p>
                            </div>
                        </div>
                    </div>
                    <div className="ogc-right">
                        <img src={nab} alt="VVVV"/>
                    </div>
                </div>
                <div className="ogc">
                    <div className="ogc-left">
                        <h2>Case Study</h2>
                        <Link to={CaseStudy3} style={{color:'black'}} target="_blank" rel="noopener noreferrer"><h1>Global Financial Services Leader</h1></Link>
                        <p>A global financial services leader aggregates corporate and compliance information from company publications using AIML Data aggregation platform.</p>
                        <div className="ogc-left-mid">
                            <Link to={CaseStudy3} target="_blank" rel="noopener noreferrer"><p className="ogc-left-mid-link">Read More</p></Link>
                        </div>
                        <div className="ogc-left-down">
                            <div className="ogc-left-down-problem">
                                <h1>Problem</h1>
                                <p>Client manually extracted data published company publications extracting 300+ data points in 23 different languages. SMEs attributed this data in 56 categories with varying error rate.</p>
                            </div>
                            <div className="pulses"></div>
                            <div className="ogc-left-down-solution">
                                <h1>Solution</h1>
                                <p>AIML custom built Bots and Crawlers extracted, parsed and uploaded the documents into AIML Central Data Repository. AIMLs DocuSense module then labeled the data in multiple categories. Approved data was fed into the annotation interface for end client use.</p>
                            </div>
                            <div className="pulses"></div>
                            <div className="ogc-left-down-result">
                                <h1>Results</h1>
                                <p>Error rate brought down to 0% in two iterations. Cost of research . operations reduced by 78% per month. Analyst utilization reduced by 80% per month. Lesser dependency on SMEs</p>
                            </div>
                        </div>
                    </div>
                    <div className="ogc-right">
                        <img src={gfsl} alt="VVVV"/>
                    </div>
                </div>
                <div className="nab">
                    <div className="ogc-left">
                        <h2>Case Study</h2>
                        <Link to={CaseStudy4} target="_blank" rel="noopener noreferrer" style={{color:'black'}}><h1>Renewables Pioneer</h1></Link>
                        <p>A U.S. based Renewables leader uses AIML Data’s Central Document Repository [Master Data Finder] to merge their acquired companies’ data into a new platform.</p>
                        <div className="ogc-left-mid">
                            <Link to={CaseStudy4} target="_blank" rel="noopener noreferrer"><p className="ogc-left-mid-link">Read More</p></Link>
                        </div>
                        <div className="ogc-left-down">
                            <div className="ogc-left-down-problem">
                                <h1>Problem</h1>
                                <p>Merge data, from acquired companies, with different structure and interface, into a new platform. One with 85,000 Investor Relations presentations with 2 million slides and the other with over 1 million pages of company filings.</p>
                            </div>
                            <div className="pulses"></div>
                            <div className="ogc-left-down-solution">
                                <h1>Solution</h1>
                                <p>AIML custom built Bots and AI-Merge tool extracts documents from both the products into the AIML Master Data Finder and maps pertinent business information. Clean usable data is uploaded into the client’s Master Data Lake for end use.</p>
                            </div>
                            <div className="pulses"></div>
                            <div className="ogc-left-down-result">
                                <h1>Results</h1>
                                <p>Both interface and data structure combined in one platform. Faster and reliable output with better quality dataset</p>
                            </div>
                        </div>
                    </div>
                    <div className="ogc-right">
                        <img src={rp} alt="VVVV"/>
                    </div>
                </div>
            </section>
        </>
    )
}

const useCases = () =>{
    const [loading,setLoading] = useState(true)

    useEffect(() => {
        document.title = "Case Studies | AIML Data | Data Aggregation & Analysis"; // Set the title based on your logic
        setTimeout(() => {
            setLoading(false);
          }, 2000);
      }, []);

    return(
        <>
        {loading?<Preloader/>:
        <>
            <Header/>
            <Spacer/>
            <UCSection1/>
            <UCSection2/>
            <Section8/>
            <Footer/>
        </>
        }
        </>
    )
}


export default useCases;