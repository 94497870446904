import React from "react";
import './S2_Card.css';
import { Link } from "react-router-dom";

const Card = ({ svg, heading, description, sentence, id }) => {

    return (
      <div className="s2-card">
        <div className="s2-card-inner">
            <div className="s2-card-svg">
                {svg /* Insert your SVG here */}
            </div>
            <h2 className="s2-card-heading">{heading}</h2>
            <p className="s2-card-description">{description}</p>
            <a href={`#${id}`} className="s2-card-link">
              <p id="special" className="s2-card-sentence">
                  {sentence}
                  <span>
                  <svg
                    width="25"
                    height="20"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    stroke="currentColor"
                    strokeWidth="1"
                    fillRule="evenodd" // Change fill-rule to fillRule
                  >
                    <path d="M21.883 12l-7.527 6.235.644.765 9-7.521-9-7.479-.645.764 7.529 6.236h-21.884v1h21.883z" />
                  </svg>
                  </span>
              </p>
            </a>
        </div>
      </div>
    );
  };
  
export default Card;