import React from "react";
import "./Sol_Card.css";

const Sol_Card = ({ img, heading, description}) => {

    return (
        <div className="ss3-card">
            <div className="ss3-card-inner">
                <div className="ss3-card-svg">
                    <img src={img} alt="ss3IMG"/>
                </div>
                <h2 className="ss3-card-heading">{heading}</h2>
                <p className="ss3-card-description">{description}</p>
            </div>
        </div>
    );
  };
  
export default Sol_Card;