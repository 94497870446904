import React from "react";
import "./S5_Card.css";
import { Link } from "react-router-dom";
import { scrollToTop } from "../Pages/Home";
const Card5 = ({ img, heading, description, sentence }) => {

    return (
        <div className="s5-card">
            <div className="s5-card-inner">
                <div className="s5-card-img">
                    <img src={img} alt="img"/>
                </div>
                <h2 className="s5-card-heading">{heading}</h2>
                <p className="s5-card-description">{description}</p>
                <Link onClick={scrollToTop} to={"/use-cases/"} className="s5-card-link">
                    <p className="s5-card-sentence">
                        {sentence}
                        <span>
                        <svg
                            width="25"
                            height="20"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="currentColor"
                            stroke="currentColor"
                            strokeWidth="1"
                            fillRule="evenodd" // Change fill-rule to fillRule
                        >
                            <path d="M21.883 12l-7.527 6.235.644.765 9-7.521-9-7.479-.645.764 7.529 6.236h-21.884v1h21.883z" />
                        </svg>
                        </span>
                    </p>
                </Link>
            </div>
        </div>
    );
  };
  
export default Card5;