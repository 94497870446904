import React from "react";
import "./Sol_Card_S.css";

const Sol_Card_S = ({ heading, description}) => {

    return (
        <div className="ss5-card">
            <div className="ss5-card-inner">
                <h2 className="ss5-card-heading">{heading}</h2>
                <p className="ss5-card-description">{description}</p>
            </div>
        </div>
    );
  };
  
export default Sol_Card_S;