import React,{useEffect,useState} from "react";
import './Contact.css';
import {Spacer,Section8} from '../Pages/Home.js';
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import { Preloader } from "./About";


const Contact = () =>{
    const [loading,setLoading] = useState(true)
    useEffect(() => {
        document.title = "Contact Us - AIML Data"; // Set the title based on your logic
        setTimeout(() => {
            setLoading(false);
          }, 2000);
      }, []);
    return(

        <>
        {loading?<Preloader/>:
        <>
            <Header/>
            <Spacer/>
            <Section8/>
            <Footer/>
            </>
        }
        </>
    )
}

export default Contact;