import React from "react";
import "./S4_Card.css";
import { Link } from "react-router-dom";
import { scrollToTop } from "../Pages/Home";

const Card4 = ({ svg, heading, description, sentence }) => {

    return (
        <div className="s4-card">
            <div className="s4-card-inner">
                <div className="s4-card-svg">
                    {svg /* Insert your SVG here */}
                </div>
                <h2 className="s4-card-heading">{heading}</h2>
                <p className="s4-card-description">{description}</p>
                <Link onClick={scrollToTop} to={"/use-cases/"}>
                <p className="s4-card-sentence">
                    {sentence}
                    <span>
                    <svg
                        width="25"
                        height="20"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        stroke="currentColor"
                        strokeWidth="1"
                        fillRule="evenodd" // Change fill-rule to fillRule
                    >
                        <path d="M21.883 12l-7.527 6.235.644.765 9-7.521-9-7.479-.645.764 7.529 6.236h-21.884v1h21.883z" />
                    </svg>
                    </span>
                </p>
                </Link>
            </div>
        </div>
    );
  };
  
export default Card4;