import React from "react";
import al_logo_color from "../Images/al_logo_color.png";
import { Link } from "react-router-dom";
import './Footer.css';
import { scrollToTop } from "../Pages/Home";

import FAQ from '../PDF Files/AIML-FAQs.pdf';
import Privacy from '../PDF Files/AIML-Privacy-Policy.pdf';
import TOU from '../PDF Files/AIML-Terms-of-Use.pdf';

const Footer = () =>{

    var today = new Date();
    var year = today.getFullYear();
    return(
        <>
            <footer className="custom-footer">
                <div className="footer-top">
                    <div className="footer-column">
                        <div className="logo">
                            <Link onClick={scrollToTop} to={"/"}>
                                <img src={al_logo_color} alt="Logo" />
                            </Link>
                        </div>
                        <p>Manage Data Better.</p>
                        <p>Label Data Faster.</p>
                        <p>Focus on Reliable Data.</p>
                    </div>
                    <div className="footer-column">
                        <h3 className="column-heading">Solutions</h3>
                        <ul className="column-list">
                            <li><Link onClick={scrollToTop} to={"/solutions/"}>Labelling & Annotation</Link></li>
                            <li><Link onClick={scrollToTop} to={"/solutions/"}>Data Workflows</Link></li>
                            <li><Link onClick={scrollToTop} to={"/solutions/"}>Value Addition</Link></li>
                            <li><Link onClick={scrollToTop} to={"/solutions/"}>Data Integrity</Link></li>
                            <li><Link onClick={scrollToTop} to={"/solutions/"}>Multisource Tagging</Link></li>
                        </ul>
                    </div>
                    <div className="footer-column">
                        <h3 className="column-heading">Insights</h3>
                        <ul className="column-list">
                            <li><Link onClick={scrollToTop} to={"/user-stories/"}>User Stories</Link></li>
                            <li><Link onClick={scrollToTop} to={"/user-stories/"}>Webinars</Link></li>
                            <li><Link onClick={scrollToTop} to={"/use-cases/"}>Case Studies</Link></li>
                            <li><Link onClick={scrollToTop} to={"/use-cases/"}>Research & Guides</Link></li>
                        </ul>
                    </div>
                    <div className="footer-column">
                        <h3 className="column-heading">Resources</h3>
                        <ul className="column-list">
                            <li><Link onClick={scrollToTop} to={"/blog/"}>Blogs</Link></li>
                            <li><Link onClick={scrollToTop} to={"/blog/"}>News & Updates</Link></li>
                            <li><Link onClick={scrollToTop} to={"/blog/"}>Community</Link></li>
                            <li><Link to={FAQ} target="_blank"  rel="noopener noreferrer">Help Center & FAQ's</Link></li>
                        </ul>
                    </div>
                    <div className="footer-column">
                        <h3 className="column-heading">Company</h3>
                        <ul className="column-list">
                            <li><Link onClick={scrollToTop} to={"/about-us/"}>About</Link></li>
                            <li><Link onClick={scrollToTop} to={"/solutions/"}>Solutions</Link></li>
                            <li><Link onClick={scrollToTop} to={"/contact-us/"}>Contact Us</Link></li>
                            <li><Link to={Privacy} target="_blank" rel="noopener noreferrer">Privacy</Link></li>
                            <li><Link to={TOU} target="_blank" rel="noopener noreferrer">Policy Terms</Link></li>
                        </ul>
                    </div>
                </div>

                <div className="footer-bottom">
                    <div className="copyright-text">
                        <Link onClick={scrollToTop} to={"/"}>© {year} AIML data, All rights reserved</Link>
                    </div>
                    <div className="footer-links">
                        <Link to={"mailto:info@aimldata.com"}>@</Link>
                        <Link to={"https://www.linkedin.com/company/100955998/admin/feed/posts/"} target="_blank">
                            <svg xmlns="http://www.w3.org/2000/svg" height="25" viewBox="0 0 72 72" width="25">
                                <g fill="currentColor" fill-rule="evenodd">
                                    <path d="M36,72 L36,72 C55.882251,72 72,55.882251 72,36 L72,36 C72,16.117749 55.882251,-3.65231026e-15 36,0 L36,0 C16.117749,3.65231026e-15 -2.4348735e-15,16.117749 0,36 L0,36 C2.4348735e-15,55.882251 16.117749,72 36,72 Z" fill="#fff"/>
                                    <path d="M59,57 L49.959375,57 L49.959375,41.6017895 C49.959375,37.3800228 48.3552083,35.0207581 45.0136719,35.0207581 C41.3785156,35.0207581 39.4792969,37.4759395 39.4792969,41.6017895 L39.4792969,57 L30.7666667,57 L30.7666667,27.6666667 L39.4792969,27.6666667 L39.4792969,31.6178624 C39.4792969,31.6178624 42.0989583,26.7704897 48.3236979,26.7704897 C54.5455729,26.7704897 59,30.5699366 59,38.4279486 L59,57 Z M20.372526,23.8257036 C17.4048177,23.8257036 15,21.4020172 15,18.4128518 C15,15.4236864 17.4048177,13 20.372526,13 C23.3402344,13 25.7436198,15.4236864 25.7436198,18.4128518 C25.7436198,21.4020172 23.3402344,23.8257036 20.372526,23.8257036 Z M15.8736979,57 L24.958724,57 L24.958724,27.6666667 L15.8736979,27.6666667 L15.8736979,57 Z" fill="currentColor"/>
                                </g>
                            </svg>
                        </Link>
                    </div>
                </div>
            </footer>
        </>
    )
}

export default Footer;