import React,{useEffect,useState} from "react";
import './Solutions.css';
import { Link } from "react-router-dom";

import Header from "../Components/Header";
import Footer from "../Components/Footer";
import { Section8, scrollToTop } from "./Home";
import SCard from "../Components/Sol_Card";
import SCardSmall from '../Components/Sol_Card_S';
import { Preloader } from "./About";

import solpic1 from '../Images/sol-pic1.png';
import solpic2 from '../Images/sol-pic2.png';
import solpic3 from '../Images/sol-pic3.png';
import solpic4 from '../Images/sol-pic4.png';
import solpic5 from '../Images/sol-pic5.png';
import solpic6 from '../Images/sol-pic6.png';
import solpic7 from '../Images/sol-pic7.png';
import solpic8 from '../Images/sol-pic8.png';

const Spacer = () =>{
    return(
        <>
            <div class="spacer">
                &nbsp;
            </div>
        </>
    )
}

const SolSection1 = () => {
    return(
        <>
            <section className="ss1">
                <div className="ss1-data">
                    <div className="ss1-data-left">
                    <h1>Modular Platform. Enterprise Ready.</h1>
                    <p>Simplify business processes across your organization with our AI & ML powered data enhancement platform.</p>
                    <Link onClick={scrollToTop} to={"/contact-us/"}><button className="a1-button">Request a Demo</button></Link>
                    </div>
                    <div className="ss1-data-right">
                        <img src={solpic1} alt="VVV"/>
                    </div>
                </div>
            </section>
        </>
    )
}

const SolSection2 = () =>{
    return(
        <>
            <section className="ss2">
                <div className="ss2-data">
                    <h1>Platform to manage your corporate data powered by AI & ML</h1>
                    <p>Our customers embed deep learning models into their business processes or customer experience combining the best of both services and software. We develop customized AI & ML algorithms to automate future data labeling and capturing processes.</p>
                    <div className="ss2-data-3">
                        <div className="ss2-data-3-left">
                            <img src={solpic2} alt="VVV"/>
                        </div>
                        <div className="ss2-data-3-right">
                            <h1 style={{marginLeft:'0',marginRight:'0'}}>How it works?</h1>
                            <p style={{marginLeft:'0',marginRight:'0'}}>AIML Data simplifies business processes and enhances user experience by using light weight, autonomous and flexible AI driven data labeling models. Our blend of offerings equipped with a cutting-edge modular AI platform, accelerate the process of managing training data, data labeling and  insight building.</p>
                            <ul>
                                <li>We begin by receiving client’s data and their information management goals.</li>
                                <li>Our team of specialists uses our machine learning software to clean and label clients’ data.</li>
                                <li>We use your data to develop a system that automates and tracks workflow processes.</li>
                            </ul>
                        </div>
                    </div>
                    <p>Our offering is agnostic of width-depth-spread of the organization as well as the IT infrastructure of the organization.</p>
                </div>
                <div className="dot-square-purple" style={{position:'absolute', height:'100px',width:'100px',left:'0',top:'75%'}}></div>
            </section>
        </>
    )
}

const SolSection3 = () =>{
    return(
        <>
            <section className="ss3">
                <h1 id="ss3-heading">Enhance data accuracy and fast track your AI & ML initiatives with us</h1>
                <p id="ss3-description">Our unique proposition help organizations spend more time driving the business decisions democratically with increased agility and reliability.</p>
                <div className="ss3-card-deck">
                    <SCard
                        img={solpic3}
                        heading="Accuracy more than 99%"
                        description="Mitigates operational risks and provides access to clean data for better decision-making"
                    />
                    <SCard
                        img={solpic4}
                        heading="Rework decreased by 98%"
                        description="Allows client to cross-deploy intellectual workforce and flattens the process architecture"
                    />
                    <SCard
                        img={solpic5}
                        heading="Costs reduced by 69%"
                        description="Creates operational synergies for the client and enables more budgets for strategic activities"
                    />
                </div>
                <div className="dot-square-purple" style={{position:'absolute',height:'100px',width:'100px',right:'0',top:'213%'}}></div>
                <div className="dot-square-blue" style={{position:'absolute',height:'100px',width:'100px',top:'271%'}}></div>
            </section>
        </>
    )
}

const SolSection4 = () =>{
    return(
        <>
            <section className="ss4">
            <div className="cid">
                <div className="cid-left">
                    <h1>Continuously improve data to support model development</h1>
                    <p>We train our AI & ML Models from a representative dataset labelled by our expert analysts. Noisy dataset is sent to human labelers for a second round of quality check.</p>
                    <p>Highly accurate data is then sent to the AI&ML Model to enhance the existing training data</p>
                    <p>Accurate dataset ready for end user deployment</p>
                    <Link onClick={scrollToTop} to={"/blog/"}><p style={{fontWeight:'900',fontSize:'18px'}}>Learn more from our experts</p></Link>
                </div>
                <div className="cid-right">
                    <img src={solpic6} alt="CHECK"/>
                </div>
            </div>
            <div className="pasm">
                <div className="pasm-left">
                    <h1>Platform Agnostic + Specialized Manpower</h1>
                    <p>AIML Data’s SMEs analyze client data and draft client’s problem statement which is immediately relayed to data science team for PoC development. We use agile and lean principles to make it easier and faster for everyone to progress.</p>
                    <p>Models are supported with rule engines and bots. Our modular AI platform is continuously evolving, enabling the specialists to modify the logic on the go. We provide clients the flexibility to define and consume information at a click of few buttons</p>
                    <Link onClick={scrollToTop} to={"/blog/"}><p style={{fontWeight:'900',fontSize:'18px'}}>Learn more from our experts</p></Link>
                </div>
                <div className="pasm-right">
                    <img src={solpic7} alt="CHECK"/>
                </div>
            </div>
            <div className="cid">
                <div className="cid-left">
                    <h1>Real-time Insights with interactive dashboards</h1>
                    <p>We offer a user friendly interface to create, delete and update labels. These role-based workspaces facilitate in streamlining operations. Our interactive dashboards gives insights on all the tasks and project progress</p>
                    <p>Our dashboards enable collaboration between managers, reviewers and annotators. The robust AI&ML engine enables high volume data processing</p>
                    <p>Finally, the clients can access Up-to-date reviews and completion status on all tasks and Import/Export the data in a CSV format</p>
                    <Link onClick={scrollToTop} to={"/blog/"}><p style={{fontWeight:'900',fontSize:'18px'}}>Learn more from our experts</p></Link>
                </div>
                <div className="cid-right">
                    <img src={solpic8} alt="CHECK"/>
                </div>
            </div>
            </section>
        </>
    )
}

const SolSection5 = () =>{
    return(
        <>
            <section className="ss5">
                <h1 id="ss5-heading">We are Enterprise ready!</h1>
                <p id="ss5-description">Our offering is agnostic of width-depth-spread of the organization as well as the IT infrastructure of the organization. We help our partners manage data BETTER-FASTER-RELIABLE with limited uptick in operational cost.</p>
                <div className="ss5-card-deck">
                    <SCardSmall
                        heading="Data ingest"
                        description="Quickly and securely integrate to data pipelines or upload data locally."
                    />
                    <SCardSmall
                        heading="Model traning"
                        description="Train custom models or choose from leading model frameworks."
                    />
                    <SCardSmall
                        heading="Product serving"
                        description="Deploy your models within AIML Data platform or export to the service of your choice."
                    />
                    <SCardSmall
                        heading="Infrastructure"
                        description="Host AIML Data platform within the secure infrastructure of your choice."
                    />
                </div>
            </section>
        </>
    )
}


const Solutions = () =>{
    const [loading,setLoading] = useState(true)

    useEffect(() => {
        document.title = "AI Driven Data Labeling Models & Machine Learning | AIML Data"; // Set the title based on your logic
        setTimeout(() => {
            setLoading(false);
          }, 2000);

      }, []);
    return(
        <>
        {loading?<Preloader/>:
        <>
        <Header/>
        <Spacer/>
        <SolSection1/>
        <SolSection2/>
        <SolSection3/>
        <SolSection4/>
        <SolSection5/>
        <Section8/>
        <Footer/>
        </>
        }
        </>
    )
}


export default Solutions;