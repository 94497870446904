import React ,{useState,useEffect} from "react";
import { Link } from "react-router-dom";
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import { Spacer } from "./About";
import { Preloader } from "./About";
import './Feed1.css';

import hades from '../Images/hades.png';
import aics from'../Images/aics.png';
import fraud from '../Images/fraud.png';
import student from '../Images/student-blog-pic.png';
import fantastic1 from '../Images/mister-fantastic-the-impending-doom.png';
import conversational from '../Images/conversational.png';


const Feed5 = () =>{
    const [loading,setLoading] = useState(true)

    useEffect(() => {
        document.title = "The Role of AI and ML in Edtech and Personalized Learning - AIML Data";
        setTimeout(() => {
            setLoading(false);
          }, 2000);
      }, []);
    
    const Paragraph = () =>{
        return(
        <>
            <div className="f1">

                <div className="f1-left">

                    <img src={student} alt=""/>

                    <h1 className="mheading">The Role of AI and ML in Edtech and Personalized Learning</h1>
                    <div className="art-details">
                        <span><svg xmlns="http://www.w3.org/2000/svg" class="svg-icon" style={{width: "1em", height: "1em",verticalAlign: "middle",fill: "currentColor",overflow: "hidden"}} viewBox="0 0 1024 1024" version="1.1"><path d="M843.282963 870.115556c-8.438519-140.515556-104.296296-257.422222-233.908148-297.14963C687.881481 536.272593 742.4 456.533333 742.4 364.088889c0-127.241481-103.158519-230.4-230.4-230.4S281.6 236.847407 281.6 364.088889c0 92.444444 54.518519 172.183704 133.12 208.877037-129.611852 39.727407-225.46963 156.634074-233.908148 297.14963-0.663704 10.903704 7.964444 20.195556 18.962963 20.195556l0 0c9.955556 0 18.299259-7.774815 18.962963-17.73037C227.745185 718.506667 355.65037 596.385185 512 596.385185s284.254815 122.121481 293.357037 276.195556c0.568889 9.955556 8.912593 17.73037 18.962963 17.73037C835.318519 890.311111 843.946667 881.019259 843.282963 870.115556zM319.525926 364.088889c0-106.287407 86.186667-192.474074 192.474074-192.474074s192.474074 86.186667 192.474074 192.474074c0 106.287407-86.186667 192.474074-192.474074 192.474074S319.525926 470.376296 319.525926 364.088889z"/></svg> AIML Data</span>
                        <span style={{fontSize:"13px"}}><svg width="10" height="10" viewBox="0 0 190 190" xmlns="http://www.w3.org/2000/svg"><circle cx="100" cy="100" r="80" stroke="black" stroke-width="5" fill="white"/><line x1="100" y1="100" x2="100" y2="40" stroke="black" stroke-width="5" transform="rotate(150, 100, 100)" /><line x1="100" y1="100" x2="100" y2="30" stroke="black" stroke-width="5" transform="rotate(0, 100, 100)" /><circle cx="100" cy="100" r="2" fill="black" /></svg> July 4, 2022</span>
                        <span><svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 100 100"><rect x="10" y="20" width="80" height="50" rx="5" ry="5" fill="none" stroke="black" stroke-width="2" /><path d="M10 20 L50 20 L40 10 L10 10 Z" fill="none" stroke="black" stroke-width="2" /></svg> Uncategorized</span>
                        <span><svg xmlns="http://www.w3.org/2000/svg" class="svg-icon" style={{width: "1.142578125em",height: "1em",verticalAlign: "middle",fill: "currentColor",overflow: "hidden"}} viewBox="0 0 1170 1024" version="1.1"><path d="M841.134446 1024a36.790489 36.790489 0 0 1-17.590681-4.46167L490.052242 837.487579h-110.627465C173.74912 837.487579 0 656.095108 0 441.45954V373.035081A376.681947 376.681947 0 0 1 379.424777 0.01024h411.424457A376.681947 376.681947 0 0 1 1170.274012 373.035081v68.278174a369.367735 369.367735 0 0 1-292.568503 363.955218V987.428937a36.571063 36.571063 0 0 1-36.571063 36.571063zM379.424777 72.823226A303.539822 303.539822 0 0 0 73.142126 373.035081v68.278174c0 172.139993 143.13914 323.141911 306.282651 323.141912h119.989657a36.900202 36.900202 0 0 1 17.590682 4.46167L804.563383 926.099265v-150.233927a36.571063 36.571063 0 0 1 30.28084-35.839641C994.184344 712.26826 1097.131886 595.058004 1097.131886 441.45954V373.035081a303.539822 303.539822 0 0 0-306.282652-300.358139h-411.424457z"/></svg> 0 Comments</span>
                    </div>
                    <p>Edtech refers to any form of technology used in the classroom, and its implementation has been on an upward trajectory in recent years.</p>
                    <p>In the same context, personalized learning is an approach that adjusts elements of learning, such as technological resources, methodology, and learning pace to match the different needs and preferences of every student.</p>
                    <p>Stakeholders have continued to streamline edtech and personalized learning by capitalizing on AI and ML. For instance, institutions use AI to discover the strengths and shortcomings of students so they can tailor a comprehensive learning process for each student. Now more than ever, schools are incorporating these technologies to remain competitive and relevant in the fast-changing world of technology.</p>
                    
                    <h2><strong>Using AI and ML to improve the efficiency of the learning process</strong></h2>
                    <p>The traditional method of teaching requires tutors to perform many time-consuming tasks, such as filling, grading, and sorting out teaching materialto name a few. Artificial intelligence (AI) and machine learning (ML) enable capabilities that seamlessly perform these tasks on behalf of teachers, and in a much more accurate way. With that sorted, tutors can spare a lot of time to spend on more meaningful engagements that increase the value of the learning process.</p>
                    <p>AI and ML technologies go on to enable the same kind of efficiency when it comes to personalized learning. For example, teachers can leverage the technology to come up with personalized recommendations for each student according to the student’s history, the pace of learning, and objectivesamong other elements.</p>
                    <p>These practices boost the overall efficiency of the classroom.</p>

                    <h2><strong>Monitoring of student cognitive wellness</strong></h2>
                    <p>The traditional method of learning did not pay much attention to the mental wellness of students, but AI and ML technologies are solving this problem. Experts have come up with AI-enabled resources that evaluate the cognitive abilities of students and offer solutions to enhance the same. These functionalities provide a comprehensive report based on fundamental cognitive skills to understand the brain of a particular student.</p>
                    <p>For instance, they can access how adolescence and puberty are affecting class performance and go ahead and offer a personalized solution. One such leading company is Cognit, a company based in San Francisco. Cognit has helped thousands of students since its inception. It collects behavioral data during learning and analyzes the data to provide useful insights to help boost brain performance and classroom outcomes.</p>

                    <h2><strong>AI-enabled chatbots</strong></h2>
                    <p>Did you know that the first chatbot was created back in 1966 even before the inception of personal computers? Chatbots have undergone an extensive revolution to take the form that we know today. The education sector has been keen to harness the potential of modern chatbots.</p>
                    <p>One major use of chatbots in the education sector is to take students through orientation. The students can ask questions, program-related questions, and the location of various facilities within the institution, and get instant, accurate answers. Further, the data collected by these chatbots can enable educators and administrators to analyze issues and concerns better, allowing them to make the students’ experience better.</p>
                    <p>Some institutions use chatbots in the health sector, whereby the bots collect information about mental wellness and other issues as all cases do not require the intervention of human experts. Sometimes the chatbots will engage the patient while awaiting the availability of a human professional.</p>

                    <h2><strong>More effective education for kids</strong></h2>
                    <p>AI and ML are not only useful to more advanced students but also to kids.</p>
                    <p>An edtech solution company called Bamboo Learning is at the forefront of offering better education to kids through AI and ML. The company capitalizes on fun activities to help students understand listening, speaking, music, and more extensive disciplines. It uses already existing resources, such as Amazon Alexa, to offer voice usage and other functionalities. The voice-recognition and interactive learning help to analyze problems and give feedback immediately to ensure a lively session.</p>
                    <p>Such applications are becoming invaluable as they personalize the learning experience for kids and prepare them adequately for their future.</p>

                    <h2><strong>Personalizing the learning experience</strong></h2>
                    <p>Different students have different brain power and capabilities, which means that the traditional method of teaching is unfair to some students. AI and ML are changing this narrative by bringing capabilities that help every student at their own pace. These include:</p>
                    <ul>
                        <li>Using student data to pinpoint their weaknesses, and guiding the tutor on how best to personalize the experience to go around these weaknesses</li>
                        <li>Discovering the best way, a student likes to learn, be it by watching videos, reading, or listening to audio. With this information, the tutor can optimize the learning process easily</li>
                        <li>Using big data to match a student to the most ideal teacher to facilitate a better outcome for the student</li>
                        <li>Deciphering the actual quality of learning materials by analyzing performance, level of dedication, and other parameters</li>
                        <li>Recommending corrections to course materials after a fast and accurate analysis</li>
                    </ul>
                    <p>These are just some of the ways in which AI and ML are shaping personalized learning. The future will see even much more capabilities come into the picture, all geared toward a seamless, highly beneficial learning process.</p>

                    <h2><strong>Better resource allocation</strong></h2>
                    <p>The traditional teaching method involves potential wastage of resources due to the generalization of the learning process. On the other hand, AI and ML ensure that every student is allocated only the material that will benefit them, thereby leaving other material for other students. Accurate resource allocation also ensures a smooth, seamless, and quick training process for both students and teachers. With this in place, the personnel can focus on preparing better content for learning and doing other value-added tasks like accurate scheduling.</p>
                    <p>AI and ML technologies are revamping the education sector and preparing it for the future. Among other uses, the technologies enable an environment in which every student can undergo a highly focused, personalized learning process that factors in their strengths, and weaknesses and builds the learning experience around their strengths.</p>
                    <p>The use of AI and ML in edtech and personalized learning is still in its nascent stages, but the results so far are promising. Any institution or individual trying to venture into modern methods of teaching or learning should explore the possibilities of AI and ML. Experts advise institutions to outsource AI and ML capabilities from companies that offer them instead of creating solutions themselves. This is because the companies can keep up with the highly changing dynamics of these technologies and can adapt to new changes faster.</p>


                    <div className="f1-left-list">
                        <div style={{display:'flex',flexDirection:'row'}}>
                            <svg style={{transform:"rotateZ(270deg)"}} width="15" height="15" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path d="M151.5 347.8L3.5 201c-4.7-4.7-4.7-12.3 0-17l19.8-19.8c4.7-4.7 12.3-4.7 17 0L160 282.7l119.7-118.5c4.7-4.7 12.3-4.7 17 0l19.8 19.8c4.7 4.7 4.7 12.3 0 17l-148 146.8c-4.7 4.7-12.3 4.7-17 0z" fill="#13aff0"/></svg><h1 style={{fontSize:"15px"}}>YOU MIGHT ALSO LIKE</h1>
                        </div>
                        <div className="imte-display">
                            <div style={{display:"flex",flexDirection:"column",alignItems:"center"}}>
                                <Link to={"/mister-fantastic-the-impending-doom/"}><img style={{height:"140px",width:"230px"}} src={fantastic1} alt="student"/></Link>
                                <Link style={{textAlign:"center",fontSize:"15px",width:"75%"}} to={"/mister-fantastic-the-impending-doom/"} className="f1-link3">Mister Fantastic: The Impending Doom</Link>
                                <span style={{fontSize:"13px"}}><svg width="10" height="10" viewBox="0 0 190 190" xmlns="http://www.w3.org/2000/svg"><circle cx="100" cy="100" r="80" stroke="black" stroke-width="5" fill="white"/><line x1="100" y1="100" x2="100" y2="40" stroke="black" stroke-width="5" transform="rotate(150, 100, 100)" /><line x1="100" y1="100" x2="100" y2="30" stroke="black" stroke-width="5" transform="rotate(0, 100, 100)" /><circle cx="100" cy="100" r="2" fill="black" /></svg> July 4, 2022</span>
                            </div>
                            <div style={{display:"flex",flexDirection:"column",alignItems:"center"}}>
                                <Link to={"/the-use-of-ai-in-detecting-insurance-and-retail-financing-frauds/"}><img style={{height:"140px",width:"230px"}} src={fraud} alt="student"/></Link>
                                <Link style={{textAlign:"center",fontSize:"15px",width:"80%"}} to={"/the-use-of-ai-in-detecting-insurance-and-retail-financing-frauds/"} className="f1-link3">The Use of AI in Detecting Insurance and Retail Financing Frauds</Link>
                                <span style={{fontSize:"13px"}}><svg width="10" height="10" viewBox="0 0 190 190" xmlns="http://www.w3.org/2000/svg"><circle cx="100" cy="100" r="80" stroke="black" stroke-width="5" fill="white"/><line x1="100" y1="100" x2="100" y2="40" stroke="black" stroke-width="5" transform="rotate(150, 100, 100)" /><line x1="100" y1="100" x2="100" y2="30" stroke="black" stroke-width="5" transform="rotate(0, 100, 100)" /><circle cx="100" cy="100" r="2" fill="black" /></svg> July 4, 2022</span>
                            </div>
                            <div style={{display:"flex",flexDirection:"column",alignItems:"center"}}>
                                <Link to={"/artificial-intelligence-in-cybersecurity/"}><img style={{height:"140px",width:"230px"}} src={aics} alt="student"/></Link>
                                <Link style={{textAlign:"center",fontSize:"15px",width:"75%"}} to={"/artificial-intelligence-in-cybersecurity/"} className="f1-link3">Artificial Intelligence in Cybersecurity</Link>
                                <span style={{fontSize:"13px"}}><svg width="10" height="10" viewBox="0 0 190 190" xmlns="http://www.w3.org/2000/svg"><circle cx="100" cy="100" r="80" stroke="black" stroke-width="5" fill="white"/><line x1="100" y1="100" x2="100" y2="40" stroke="black" stroke-width="5" transform="rotate(150, 100, 100)" /><line x1="100" y1="100" x2="100" y2="30" stroke="black" stroke-width="5" transform="rotate(0, 100, 100)" /><circle cx="100" cy="100" r="2" fill="black" /></svg> July 4, 2022</span>
                            </div>

                        </div>
                    </div>
                </div>


                <div className="f1-right">
                <h2>Recent Posts</h2>
                <Link to={"/mister-fantastic-order-in-chaos-h-a-d-e-s/"} className="f1-link3">Mister Fantastic: Order in chaos – H.A.D.E.S.</Link>
                <Link to={"/mister-fantastic-the-impending-doom/"} className="f1-link3">Mister Fantastic: The Impending Doom</Link>
                <Link to={"/conversational-artificial-intelligence-overcoming-the-challenges/"} className="f1-link3">Conversational Artificial Intelligence – Overcoming the Challenges</Link>
                <Link to={"/artificial-intelligence-in-cybersecurity/"} className="f1-link3">Artificial Intelligence in Cybersecurity</Link>
                <Link to={"/the-role-of-ai-and-ml-in-edtech-and-personalized-learning/"} className="f1-link3">The Role of AI and ML in Edtech and Personalized Learning</Link>
                <Link to={"/the-use-of-ai-in-detecting-insurance-and-retail-financing-frauds/"} className="f1-link3">The Use of AI in Detecting Insurance and Retail Financing Frauds</Link>

                </div>
            </div>
        </>)

    }
    return(
    <>
    {loading?<Preloader/>:
    <>
        <Header/>
        <Spacer/>
        <Paragraph/>
        <Footer/>
    </>
    }
    </>
    )
}


export default Feed5;