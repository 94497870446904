import React, {useState,useEffect} from "react";
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import { Link } from "react-router-dom";
import './NotFound.css';
import { Preloader,Spacer } from "./About";




const NotFound = () =>{

    const [loading,setLoading] = useState(true)

    useEffect(() => {
        document.title = "Page Not Found - AIML Data";
        setTimeout(() => {
            setLoading(false);
          }, 2000);
      }, []);

    return(
        <>
        {loading?<Preloader/>:
        <>
        <Header/>
        <Spacer/>
        <div className="nf1">
            <h1>This Page Could not be found!</h1>
            <p>We are sorry. But the page you are looking for is not available.</p>
            <Link to="/"><button>BACK TO HOMEPAGE</button></Link>
        </div>
        <Footer/>
        </>
        }
        </>
    )
}


export default NotFound;