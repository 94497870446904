import React ,{useState,useEffect} from "react";
import { Link } from "react-router-dom";
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import { Spacer } from "./About";
import { Preloader } from "./About";
import './Feed1.css';

import hades from '../Images/hades.png';
import fraud from '../Images/fraud.png';
import student from '../Images/student-blog-pic.png';
import fantastic1 from '../Images/mister-fantastic-the-impending-doom.png';


const Feed3 = () =>{
    const [loading,setLoading] = useState(true)

    useEffect(() => {
        document.title = "Mister Fantastic: The Impending Doom";
        setTimeout(() => {
            setLoading(false);
          }, 2000);
      }, []);
    
    const Paragraph = () =>{
        return(
        <>
            <div className="f1">

                <div className="f1-left">

                    <img src={fantastic1} alt=""/>
                    <h1 className="mheading">Mister Fantastic: The Impending Doom</h1>
                    <div className="art-details">
                        <span><svg xmlns="http://www.w3.org/2000/svg" class="svg-icon" style={{width: "1em", height: "1em",verticalAlign: "middle",fill: "currentColor",overflow: "hidden"}} viewBox="0 0 1024 1024" version="1.1"><path d="M843.282963 870.115556c-8.438519-140.515556-104.296296-257.422222-233.908148-297.14963C687.881481 536.272593 742.4 456.533333 742.4 364.088889c0-127.241481-103.158519-230.4-230.4-230.4S281.6 236.847407 281.6 364.088889c0 92.444444 54.518519 172.183704 133.12 208.877037-129.611852 39.727407-225.46963 156.634074-233.908148 297.14963-0.663704 10.903704 7.964444 20.195556 18.962963 20.195556l0 0c9.955556 0 18.299259-7.774815 18.962963-17.73037C227.745185 718.506667 355.65037 596.385185 512 596.385185s284.254815 122.121481 293.357037 276.195556c0.568889 9.955556 8.912593 17.73037 18.962963 17.73037C835.318519 890.311111 843.946667 881.019259 843.282963 870.115556zM319.525926 364.088889c0-106.287407 86.186667-192.474074 192.474074-192.474074s192.474074 86.186667 192.474074 192.474074c0 106.287407-86.186667 192.474074-192.474074 192.474074S319.525926 470.376296 319.525926 364.088889z"/></svg> AIML Data</span>
                        <span style={{fontSize:"13px"}}><svg width="10" height="10" viewBox="0 0 190 190" xmlns="http://www.w3.org/2000/svg"><circle cx="100" cy="100" r="80" stroke="black" stroke-width="5" fill="white"/><line x1="100" y1="100" x2="100" y2="40" stroke="black" stroke-width="5" transform="rotate(150, 100, 100)" /><line x1="100" y1="100" x2="100" y2="30" stroke="black" stroke-width="5" transform="rotate(0, 100, 100)" /><circle cx="100" cy="100" r="2" fill="black" /></svg> July 4, 2022</span>
                        <span><svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 100 100"><rect x="10" y="20" width="80" height="50" rx="5" ry="5" fill="none" stroke="black" stroke-width="2" /><path d="M10 20 L50 20 L40 10 L10 10 Z" fill="none" stroke="black" stroke-width="2" /></svg> Uncategorized</span>
                        <span><svg xmlns="http://www.w3.org/2000/svg" class="svg-icon" style={{width: "1.142578125em",height: "1em",verticalAlign: "middle",fill: "currentColor",overflow: "hidden"}} viewBox="0 0 1170 1024" version="1.1"><path d="M841.134446 1024a36.790489 36.790489 0 0 1-17.590681-4.46167L490.052242 837.487579h-110.627465C173.74912 837.487579 0 656.095108 0 441.45954V373.035081A376.681947 376.681947 0 0 1 379.424777 0.01024h411.424457A376.681947 376.681947 0 0 1 1170.274012 373.035081v68.278174a369.367735 369.367735 0 0 1-292.568503 363.955218V987.428937a36.571063 36.571063 0 0 1-36.571063 36.571063zM379.424777 72.823226A303.539822 303.539822 0 0 0 73.142126 373.035081v68.278174c0 172.139993 143.13914 323.141911 306.282651 323.141912h119.989657a36.900202 36.900202 0 0 1 17.590682 4.46167L804.563383 926.099265v-150.233927a36.571063 36.571063 0 0 1 30.28084-35.839641C994.184344 712.26826 1097.131886 595.058004 1097.131886 441.45954V373.035081a303.539822 303.539822 0 0 0-306.282652-300.358139h-411.424457z"/></svg> 0 Comments</span>
                    </div>
                    <p>Stretches and stretches of barren land that used to be green about a month back. “That is a site you don’t want to get used to” says Yuri sitting next to me on the jet while we fly over Tanzania.</p>
                    <p>“How bad is it” I ask.</p>
                    <p>“The vegetation has been dying at an exponential rate. The wildlife, well the animals that survived migrated to Kenya. The spoilation is less intense there” Yuri hands me over a file titled ‘Nanite breakout in Africa’.</p>
                    <p>We were able to stop Dr. Doom also known as Dr. Von Doom from causing the global war, but we were too late. His program, what he called the ‘The Purge’ had caused major havoc around the world. Power generation grids in many parts of the world have gone offline, and vegetation around the globe is dying at a supremely alarming rate; at this rate, we will run out of food to feed the global population and what I am scared of is that food scarcity will bring out the worst in humans. That’s the point Victor was trying to prove. We cannot let him succeed. We must not let him succeed!</p>
                    <p>Yuri while still looking at her laptop, in a deep voice mentions, “Reports are still coming in of the blue fever spreading across South America. Lima has been identified as the epicenter of the disease”.</p>
                    <p>The morning has not started the way she would have hoped for. I have known Yuri for almost 10 years now. She used to be a CIA agent. Now she practically runs the show at the agency.</p>
                    <p>“Can you please turn off the news?” I ask. Yuri does that without much resistance. I could sense her growing agony as we approach the project station.</p>
                    <p>“We are going to be fine, I have seen you deal with worse, you have 18 doctorate degrees, let’s put a couple of them to use today!” says Yuri while looking at the camp set up from her window while our jet lands in the barren land of nothingness.</p>
                    <p>We step outside of the jet to the horrors of the impending doom.</p>
                    <p>“Dr. Reed Richards, the smartest man alive, aren’t we glad to see you here”, the short man here to receive us at the landing site is none other than Dr. Henry Richardson. Henry and I go a long way back. He is the world’s leading authority on nanotechnology. We have been working together to devise a strategy to counter the effect of Victor’s nanite spread. Diamonds cut Diamonds.</p>
                    <p>“It’s good to see you too Henry. Did you read my proposal?”</p>
                    <p>“I did, it’s eccentric and brilliant at the same time. I have studied the theoretical possibility of using AI-powered nanobots to identify and eliminate selective species of pests! But we are not dealing with ‘pests’ here. Are we? Also, to use it at a scale this big sounds more like science fiction than practical science…”</p>
                    <p>“I agree, it’s a bold step but what other alternatives do we have?” I replied.</p>
                    <p>We specifically picked one of the most uninhabitable places on the face of the earth to carry out our experiment.</p>
                    <p>“Let me show you to your chambers. You wouldn’t want to be out here when the sandstorm hits. The lack of vegetation cover on these lands has made the soil extremely loose, making the sandstorms worse than ever”, Henry escorts me and Yuri to our chambers. It is fascinating to see how much the world has changed since the beginning of this nightmare. The structure we have been brought to looks more like an igloo than anything else. Recently made structures capable of withstanding stressful environmental impacts. I wouldn’t have believed anyone about 2 weeks back if someone told me that I will find myself in an igloo in the middle of Serengeti.</p>
                    <p>“Dr. Richards, we might need a miracle here” says Yuri while helping me set up my remote lab.</p>
                    <p>“I have never given up hope before … We’re not stopping now. If there’s a way out of this, so help me…I’ll find it.”</p>
                    <p>“We need you to be Mister Fantastic. Now, more than ever. I have a strong feeling that this is just the tip of the iceberg.”</p>
                    <p>I look outside the window from my igloo. We are at an elevation; you could see miles of dead grassland right in front of your sky. The sky has taken an orange hue. This is what I used to imagine the apocalypse would look like.</p>
                    <p>Is the world as we know it about to come to an end? The most precious resource at hand is time. I stand between Victor’s ploy and the human civilization, and I stand strong. Serengeti, you better not disappoint me.</p>
                    <div className="f1-left-list">
                        <div style={{display:'flex',flexDirection:'row'}}>
                            <svg style={{transform:"rotateZ(270deg)"}} width="15" height="15" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path d="M151.5 347.8L3.5 201c-4.7-4.7-4.7-12.3 0-17l19.8-19.8c4.7-4.7 12.3-4.7 17 0L160 282.7l119.7-118.5c4.7-4.7 12.3-4.7 17 0l19.8 19.8c4.7 4.7 4.7 12.3 0 17l-148 146.8c-4.7 4.7-12.3 4.7-17 0z" fill="#13aff0"/></svg><h1 style={{fontSize:"15px"}}>YOU MIGHT ALSO LIKE</h1>
                        </div>
                        <div className="imte-display">
                            <div style={{display:"flex",flexDirection:"column",alignItems:"center"}}>
                                <Link to={"/mister-fantastic-order-in-chaos-h-a-d-e-s/"}><img style={{height:"140px",width:"230px",objectFit:'contain'}} src={hades} alt="student"/></Link>
                                <Link style={{textAlign:"center",fontSize:"15px",width:"80%"}} to={"/mister-fantastic-order-in-chaos-h-a-d-e-s/"} className="f1-link3">Mister Fantastic: Order in chaos – H.A.D.E.S.</Link>
                                <span style={{fontSize:"13px"}}><svg width="10" height="10" viewBox="0 0 190 190" xmlns="http://www.w3.org/2000/svg"><circle cx="100" cy="100" r="80" stroke="black" stroke-width="5" fill="white"/><line x1="100" y1="100" x2="100" y2="40" stroke="black" stroke-width="5" transform="rotate(150, 100, 100)" /><line x1="100" y1="100" x2="100" y2="30" stroke="black" stroke-width="5" transform="rotate(0, 100, 100)" /><circle cx="100" cy="100" r="2" fill="black" /></svg> July 4, 2022</span>
                            </div>
                            <div style={{display:"flex",flexDirection:"column",alignItems:"center"}}>
                                <Link to={"/the-role-of-ai-and-ml-in-edtech-and-personalized-learning/"}><img style={{height:"140px",width:"230px"}} src={student} alt="student"/></Link>
                                <Link style={{textAlign:"center",fontSize:"15px",width:"75%"}} to={"/the-role-of-ai-and-ml-in-edtech-and-personalized-learning/"} className="f1-link3">The Role of AI and ML in Edtech and Personalized Learning</Link>
                                <span style={{fontSize:"13px"}}><svg width="10" height="10" viewBox="0 0 190 190" xmlns="http://www.w3.org/2000/svg"><circle cx="100" cy="100" r="80" stroke="black" stroke-width="5" fill="white"/><line x1="100" y1="100" x2="100" y2="40" stroke="black" stroke-width="5" transform="rotate(150, 100, 100)" /><line x1="100" y1="100" x2="100" y2="30" stroke="black" stroke-width="5" transform="rotate(0, 100, 100)" /><circle cx="100" cy="100" r="2" fill="black" /></svg> July 4, 2022</span>
                            </div>
                            <div style={{display:"flex",flexDirection:"column",alignItems:"center"}}>
                                <Link to={"/the-use-of-ai-in-detecting-insurance-and-retail-financing-frauds/"}><img style={{height:"140px",width:"230px"}} src={fraud} alt="student"/></Link>
                                <Link style={{textAlign:"center",fontSize:"15px",width:"75%"}} to={"/the-use-of-ai-in-detecting-insurance-and-retail-financing-frauds/"} className="f1-link3">The Use of AI in Detecting Insurance and Retail Financing Frauds</Link>
                                <span style={{fontSize:"13px"}}><svg width="10" height="10" viewBox="0 0 190 190" xmlns="http://www.w3.org/2000/svg"><circle cx="100" cy="100" r="80" stroke="black" stroke-width="5" fill="white"/><line x1="100" y1="100" x2="100" y2="40" stroke="black" stroke-width="5" transform="rotate(150, 100, 100)" /><line x1="100" y1="100" x2="100" y2="30" stroke="black" stroke-width="5" transform="rotate(0, 100, 100)" /><circle cx="100" cy="100" r="2" fill="black" /></svg> July 4, 2022</span>
                            </div>

                        </div>
                    </div>
                </div>


                <div className="f1-right">
                <h2>Recent Posts</h2>
                <Link to={"/mister-fantastic-order-in-chaos-h-a-d-e-s/"} className="f1-link3">Mister Fantastic: Order in chaos – H.A.D.E.S.</Link>
                <Link to={"/mister-fantastic-the-impending-doom/"} className="f1-link3">Mister Fantastic: The Impending Doom</Link>
                <Link to={"/conversational-artificial-intelligence-overcoming-the-challenges/"} className="f1-link3">Conversational Artificial Intelligence – Overcoming the Challenges</Link>
                <Link to={"/artificial-intelligence-in-cybersecurity/"} className="f1-link3">Artificial Intelligence in Cybersecurity</Link>
                <Link to={"/the-role-of-ai-and-ml-in-edtech-and-personalized-learning/"} className="f1-link3">The Role of AI and ML in Edtech and Personalized Learning</Link>
                <Link to={"/the-use-of-ai-in-detecting-insurance-and-retail-financing-frauds/"} className="f1-link3">The Use of AI in Detecting Insurance and Retail Financing Frauds</Link>

                </div>
            </div>
        </>)

    }
    return(
    <>
    {loading?<Preloader/>:
    <>
        <Header/>
        <Spacer/>
        <Paragraph/>
        <Footer/>
    </>
    }
    </>
    )
}


export default Feed3;