import './BlogList.css';
import './Feed1.css';
import React ,{useState,useEffect} from "react";
import { Link } from "react-router-dom";
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import { Spacer } from "./About";
import { Preloader } from "./About";

import aics from '../Images/aics.png';
import student from '../Images/student-blog-pic.png';
import fraud from '../Images/fraud.png';
import fantastic1 from '../Images/mister-fantastic-the-impending-doom.png';
import hades from '../Images/hades.png';
import conversational from '../Images/conversational.png';

const BlogList = () =>{
    const [loading,setLoading] = useState(true)

    useEffect(() => {
        document.title = "Author at AIML Data";
        setTimeout(() => {
            setLoading(false);
          }, 2000);
      }, []);

    const BlogFeed = () =>{
        return(
            <>
                <div className='b1'>
                    <div className="b1-left">
                        <div className='b1l-in'>
                            <img src={aics} alt=""/>
                            <Link to={"/artificial-intelligence-in-cybersecurity/"} className='b1-link3'><h1 className="bmheading">Artificial Intelligence in Cybersecurity</h1></Link>
                            <div className="b1-art-details">
                                <span><svg xmlns="http://www.w3.org/2000/svg" class="svg-icon" style={{width: "1em", height: "1em",verticalAlign: "middle",fill: "currentColor",overflow: "hidden"}} viewBox="0 0 1024 1024" version="1.1"><path d="M843.282963 870.115556c-8.438519-140.515556-104.296296-257.422222-233.908148-297.14963C687.881481 536.272593 742.4 456.533333 742.4 364.088889c0-127.241481-103.158519-230.4-230.4-230.4S281.6 236.847407 281.6 364.088889c0 92.444444 54.518519 172.183704 133.12 208.877037-129.611852 39.727407-225.46963 156.634074-233.908148 297.14963-0.663704 10.903704 7.964444 20.195556 18.962963 20.195556l0 0c9.955556 0 18.299259-7.774815 18.962963-17.73037C227.745185 718.506667 355.65037 596.385185 512 596.385185s284.254815 122.121481 293.357037 276.195556c0.568889 9.955556 8.912593 17.73037 18.962963 17.73037C835.318519 890.311111 843.946667 881.019259 843.282963 870.115556zM319.525926 364.088889c0-106.287407 86.186667-192.474074 192.474074-192.474074s192.474074 86.186667 192.474074 192.474074c0 106.287407-86.186667 192.474074-192.474074 192.474074S319.525926 470.376296 319.525926 364.088889z"/></svg> AIML Data</span>
                                <span style={{fontSize:"13px"}}><svg width="10" height="10" viewBox="0 0 190 190" xmlns="http://www.w3.org/2000/svg"><circle cx="100" cy="100" r="80" stroke="black" stroke-width="5" fill="white"/><line x1="100" y1="100" x2="100" y2="40" stroke="black" stroke-width="5" transform="rotate(150, 100, 100)" /><line x1="100" y1="100" x2="100" y2="30" stroke="black" stroke-width="5" transform="rotate(0, 100, 100)" /><circle cx="100" cy="100" r="2" fill="black" /></svg> July 4, 2022</span>
                                <span><svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 100 100"><rect x="10" y="20" width="80" height="50" rx="5" ry="5" fill="none" stroke="black" stroke-width="2" /><path d="M10 20 L50 20 L40 10 L10 10 Z" fill="none" stroke="black" stroke-width="2" /></svg> Uncategorized</span>
                                <span><svg xmlns="http://www.w3.org/2000/svg" class="svg-icon" style={{width: "1.142578125em",height: "1em",verticalAlign: "middle",fill: "currentColor",overflow: "hidden"}} viewBox="0 0 1170 1024" version="1.1"><path d="M841.134446 1024a36.790489 36.790489 0 0 1-17.590681-4.46167L490.052242 837.487579h-110.627465C173.74912 837.487579 0 656.095108 0 441.45954V373.035081A376.681947 376.681947 0 0 1 379.424777 0.01024h411.424457A376.681947 376.681947 0 0 1 1170.274012 373.035081v68.278174a369.367735 369.367735 0 0 1-292.568503 363.955218V987.428937a36.571063 36.571063 0 0 1-36.571063 36.571063zM379.424777 72.823226A303.539822 303.539822 0 0 0 73.142126 373.035081v68.278174c0 172.139993 143.13914 323.141911 306.282651 323.141912h119.989657a36.900202 36.900202 0 0 1 17.590682 4.46167L804.563383 926.099265v-150.233927a36.571063 36.571063 0 0 1 30.28084-35.839641C994.184344 712.26826 1097.131886 595.058004 1097.131886 441.45954V373.035081a303.539822 303.539822 0 0 0-306.282652-300.358139h-411.424457z"/></svg> 0 Comments</span>
                            </div>
                            <p>In the wake of recent ransomware attacks, such as the Colonial Pipeline attack that crippled oil distribution across Southeastern US and an increase in the amount of data stored on…</p>
                            <Link to={"/artificial-intelligence-in-cybersecurity/"}><button className="bloglist-button">Continue Reading <svg style={{transform:"rotateZ(270deg)"}} width="13" height="10" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path d="M151.5 347.8L3.5 201c-4.7-4.7-4.7-12.3 0-17l19.8-19.8c4.7-4.7 12.3-4.7 17 0L160 282.7l119.7-118.5c4.7-4.7 12.3-4.7 17 0l19.8 19.8c4.7 4.7 4.7 12.3 0 17l-148 146.8c-4.7 4.7-12.3 4.7-17 0z" fill="currentColor"/></svg></button></Link>
                        </div>

                        <div className='b1l-in'>
                            <img src={conversational} alt=""/>
                            <Link to={"/conversational-artificial-intelligence-overcoming-the-challenges/"} className='b1-link3'><h1 className="bmheading">Conversational Artificial Intelligence – Overcoming the Challenges</h1></Link>
                            <div className="b1-art-details">
                                <span><svg xmlns="http://www.w3.org/2000/svg" class="svg-icon" style={{width: "1em", height: "1em",verticalAlign: "middle",fill: "currentColor",overflow: "hidden"}} viewBox="0 0 1024 1024" version="1.1"><path d="M843.282963 870.115556c-8.438519-140.515556-104.296296-257.422222-233.908148-297.14963C687.881481 536.272593 742.4 456.533333 742.4 364.088889c0-127.241481-103.158519-230.4-230.4-230.4S281.6 236.847407 281.6 364.088889c0 92.444444 54.518519 172.183704 133.12 208.877037-129.611852 39.727407-225.46963 156.634074-233.908148 297.14963-0.663704 10.903704 7.964444 20.195556 18.962963 20.195556l0 0c9.955556 0 18.299259-7.774815 18.962963-17.73037C227.745185 718.506667 355.65037 596.385185 512 596.385185s284.254815 122.121481 293.357037 276.195556c0.568889 9.955556 8.912593 17.73037 18.962963 17.73037C835.318519 890.311111 843.946667 881.019259 843.282963 870.115556zM319.525926 364.088889c0-106.287407 86.186667-192.474074 192.474074-192.474074s192.474074 86.186667 192.474074 192.474074c0 106.287407-86.186667 192.474074-192.474074 192.474074S319.525926 470.376296 319.525926 364.088889z"/></svg> AIML Data</span>
                                <span style={{fontSize:"13px"}}><svg width="10" height="10" viewBox="0 0 190 190" xmlns="http://www.w3.org/2000/svg"><circle cx="100" cy="100" r="80" stroke="black" stroke-width="5" fill="white"/><line x1="100" y1="100" x2="100" y2="40" stroke="black" stroke-width="5" transform="rotate(150, 100, 100)" /><line x1="100" y1="100" x2="100" y2="30" stroke="black" stroke-width="5" transform="rotate(0, 100, 100)" /><circle cx="100" cy="100" r="2" fill="black" /></svg> July 4, 2022</span>
                                <span><svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 100 100"><rect x="10" y="20" width="80" height="50" rx="5" ry="5" fill="none" stroke="black" stroke-width="2" /><path d="M10 20 L50 20 L40 10 L10 10 Z" fill="none" stroke="black" stroke-width="2" /></svg> Uncategorized</span>
                                <span><svg xmlns="http://www.w3.org/2000/svg" class="svg-icon" style={{width: "1.142578125em",height: "1em",verticalAlign: "middle",fill: "currentColor",overflow: "hidden"}} viewBox="0 0 1170 1024" version="1.1"><path d="M841.134446 1024a36.790489 36.790489 0 0 1-17.590681-4.46167L490.052242 837.487579h-110.627465C173.74912 837.487579 0 656.095108 0 441.45954V373.035081A376.681947 376.681947 0 0 1 379.424777 0.01024h411.424457A376.681947 376.681947 0 0 1 1170.274012 373.035081v68.278174a369.367735 369.367735 0 0 1-292.568503 363.955218V987.428937a36.571063 36.571063 0 0 1-36.571063 36.571063zM379.424777 72.823226A303.539822 303.539822 0 0 0 73.142126 373.035081v68.278174c0 172.139993 143.13914 323.141911 306.282651 323.141912h119.989657a36.900202 36.900202 0 0 1 17.590682 4.46167L804.563383 926.099265v-150.233927a36.571063 36.571063 0 0 1 30.28084-35.839641C994.184344 712.26826 1097.131886 595.058004 1097.131886 441.45954V373.035081a303.539822 303.539822 0 0 0-306.282652-300.358139h-411.424457z"/></svg> 0 Comments</span>
                            </div>
                            <p>Across the landscape of many industries, Conversational AI has been a great tool for many use cases. It can help with customer service and sales, as well as research and…</p>
                            <Link to={"/conversational-artificial-intelligence-overcoming-the-challenges/"}><button className="bloglist-button">Continue Reading <svg style={{transform:"rotateZ(270deg)"}} width="13" height="10" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path d="M151.5 347.8L3.5 201c-4.7-4.7-4.7-12.3 0-17l19.8-19.8c4.7-4.7 12.3-4.7 17 0L160 282.7l119.7-118.5c4.7-4.7 12.3-4.7 17 0l19.8 19.8c4.7 4.7 4.7 12.3 0 17l-148 146.8c-4.7 4.7-12.3 4.7-17 0z" fill="currentColor"/></svg></button></Link>
                        </div>

                        <div className='b1l-in'>
                            <img src={fantastic1} alt=""/>
                            <Link to={"/mister-fantastic-the-impending-doom/"} className='b1-link3'><h1 className="bmheading">Mister Fantastic: The Impending Doom</h1></Link>
                            <div className="b1-art-details">
                                <span><svg xmlns="http://www.w3.org/2000/svg" class="svg-icon" style={{width: "1em", height: "1em",verticalAlign: "middle",fill: "currentColor",overflow: "hidden"}} viewBox="0 0 1024 1024" version="1.1"><path d="M843.282963 870.115556c-8.438519-140.515556-104.296296-257.422222-233.908148-297.14963C687.881481 536.272593 742.4 456.533333 742.4 364.088889c0-127.241481-103.158519-230.4-230.4-230.4S281.6 236.847407 281.6 364.088889c0 92.444444 54.518519 172.183704 133.12 208.877037-129.611852 39.727407-225.46963 156.634074-233.908148 297.14963-0.663704 10.903704 7.964444 20.195556 18.962963 20.195556l0 0c9.955556 0 18.299259-7.774815 18.962963-17.73037C227.745185 718.506667 355.65037 596.385185 512 596.385185s284.254815 122.121481 293.357037 276.195556c0.568889 9.955556 8.912593 17.73037 18.962963 17.73037C835.318519 890.311111 843.946667 881.019259 843.282963 870.115556zM319.525926 364.088889c0-106.287407 86.186667-192.474074 192.474074-192.474074s192.474074 86.186667 192.474074 192.474074c0 106.287407-86.186667 192.474074-192.474074 192.474074S319.525926 470.376296 319.525926 364.088889z"/></svg> AIML Data</span>
                                <span style={{fontSize:"13px"}}><svg width="10" height="10" viewBox="0 0 190 190" xmlns="http://www.w3.org/2000/svg"><circle cx="100" cy="100" r="80" stroke="black" stroke-width="5" fill="white"/><line x1="100" y1="100" x2="100" y2="40" stroke="black" stroke-width="5" transform="rotate(150, 100, 100)" /><line x1="100" y1="100" x2="100" y2="30" stroke="black" stroke-width="5" transform="rotate(0, 100, 100)" /><circle cx="100" cy="100" r="2" fill="black" /></svg> July 4, 2022</span>
                                <span><svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 100 100"><rect x="10" y="20" width="80" height="50" rx="5" ry="5" fill="none" stroke="black" stroke-width="2" /><path d="M10 20 L50 20 L40 10 L10 10 Z" fill="none" stroke="black" stroke-width="2" /></svg> Uncategorized</span>
                                <span><svg xmlns="http://www.w3.org/2000/svg" class="svg-icon" style={{width: "1.142578125em",height: "1em",verticalAlign: "middle",fill: "currentColor",overflow: "hidden"}} viewBox="0 0 1170 1024" version="1.1"><path d="M841.134446 1024a36.790489 36.790489 0 0 1-17.590681-4.46167L490.052242 837.487579h-110.627465C173.74912 837.487579 0 656.095108 0 441.45954V373.035081A376.681947 376.681947 0 0 1 379.424777 0.01024h411.424457A376.681947 376.681947 0 0 1 1170.274012 373.035081v68.278174a369.367735 369.367735 0 0 1-292.568503 363.955218V987.428937a36.571063 36.571063 0 0 1-36.571063 36.571063zM379.424777 72.823226A303.539822 303.539822 0 0 0 73.142126 373.035081v68.278174c0 172.139993 143.13914 323.141911 306.282651 323.141912h119.989657a36.900202 36.900202 0 0 1 17.590682 4.46167L804.563383 926.099265v-150.233927a36.571063 36.571063 0 0 1 30.28084-35.839641C994.184344 712.26826 1097.131886 595.058004 1097.131886 441.45954V373.035081a303.539822 303.539822 0 0 0-306.282652-300.358139h-411.424457z"/></svg> 0 Comments</span>
                            </div>
                            <p>Stretches and stretches of barren land that used to be green about a month back. “That is a site you don’t want to get used to” says Yuri sitting next…</p>
                            <Link to={"/mister-fantastic-the-impending-doom/"}><button className="bloglist-button">Continue Reading <svg style={{transform:"rotateZ(270deg)"}} width="13" height="10" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path d="M151.5 347.8L3.5 201c-4.7-4.7-4.7-12.3 0-17l19.8-19.8c4.7-4.7 12.3-4.7 17 0L160 282.7l119.7-118.5c4.7-4.7 12.3-4.7 17 0l19.8 19.8c4.7 4.7 4.7 12.3 0 17l-148 146.8c-4.7 4.7-12.3 4.7-17 0z" fill="currentColor"/></svg></button></Link>
                        </div>

                        <div className='b1l-in'>
                            <img src={student} alt=""/>
                            <Link to={"/the-role-of-ai-and-ml-in-edtech-and-personalized-learning/"} className='b1-link3'><h1 className="bmheading">The Role of AI and ML in Edtech and Personalized Learning</h1></Link>
                            <div className="b1-art-details">
                                <span><svg xmlns="http://www.w3.org/2000/svg" class="svg-icon" style={{width: "1em", height: "1em",verticalAlign: "middle",fill: "currentColor",overflow: "hidden"}} viewBox="0 0 1024 1024" version="1.1"><path d="M843.282963 870.115556c-8.438519-140.515556-104.296296-257.422222-233.908148-297.14963C687.881481 536.272593 742.4 456.533333 742.4 364.088889c0-127.241481-103.158519-230.4-230.4-230.4S281.6 236.847407 281.6 364.088889c0 92.444444 54.518519 172.183704 133.12 208.877037-129.611852 39.727407-225.46963 156.634074-233.908148 297.14963-0.663704 10.903704 7.964444 20.195556 18.962963 20.195556l0 0c9.955556 0 18.299259-7.774815 18.962963-17.73037C227.745185 718.506667 355.65037 596.385185 512 596.385185s284.254815 122.121481 293.357037 276.195556c0.568889 9.955556 8.912593 17.73037 18.962963 17.73037C835.318519 890.311111 843.946667 881.019259 843.282963 870.115556zM319.525926 364.088889c0-106.287407 86.186667-192.474074 192.474074-192.474074s192.474074 86.186667 192.474074 192.474074c0 106.287407-86.186667 192.474074-192.474074 192.474074S319.525926 470.376296 319.525926 364.088889z"/></svg> AIML Data</span>
                                <span style={{fontSize:"13px"}}><svg width="10" height="10" viewBox="0 0 190 190" xmlns="http://www.w3.org/2000/svg"><circle cx="100" cy="100" r="80" stroke="black" stroke-width="5" fill="white"/><line x1="100" y1="100" x2="100" y2="40" stroke="black" stroke-width="5" transform="rotate(150, 100, 100)" /><line x1="100" y1="100" x2="100" y2="30" stroke="black" stroke-width="5" transform="rotate(0, 100, 100)" /><circle cx="100" cy="100" r="2" fill="black" /></svg> July 4, 2022</span>
                                <span><svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 100 100"><rect x="10" y="20" width="80" height="50" rx="5" ry="5" fill="none" stroke="black" stroke-width="2" /><path d="M10 20 L50 20 L40 10 L10 10 Z" fill="none" stroke="black" stroke-width="2" /></svg> Uncategorized</span>
                                <span><svg xmlns="http://www.w3.org/2000/svg" class="svg-icon" style={{width: "1.142578125em",height: "1em",verticalAlign: "middle",fill: "currentColor",overflow: "hidden"}} viewBox="0 0 1170 1024" version="1.1"><path d="M841.134446 1024a36.790489 36.790489 0 0 1-17.590681-4.46167L490.052242 837.487579h-110.627465C173.74912 837.487579 0 656.095108 0 441.45954V373.035081A376.681947 376.681947 0 0 1 379.424777 0.01024h411.424457A376.681947 376.681947 0 0 1 1170.274012 373.035081v68.278174a369.367735 369.367735 0 0 1-292.568503 363.955218V987.428937a36.571063 36.571063 0 0 1-36.571063 36.571063zM379.424777 72.823226A303.539822 303.539822 0 0 0 73.142126 373.035081v68.278174c0 172.139993 143.13914 323.141911 306.282651 323.141912h119.989657a36.900202 36.900202 0 0 1 17.590682 4.46167L804.563383 926.099265v-150.233927a36.571063 36.571063 0 0 1 30.28084-35.839641C994.184344 712.26826 1097.131886 595.058004 1097.131886 441.45954V373.035081a303.539822 303.539822 0 0 0-306.282652-300.358139h-411.424457z"/></svg> 0 Comments</span>
                            </div>
                            <p>Edtech refers to any form of technology used in the classroom, and its implementation has been on an upward trajectory in recent years. In the same context, personalized learning is…</p>
                            <Link to={"/the-role-of-ai-and-ml-in-edtech-and-personalized-learning/"}><button className="bloglist-button">Continue Reading <svg style={{transform:"rotateZ(270deg)"}} width="13" height="10" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path d="M151.5 347.8L3.5 201c-4.7-4.7-4.7-12.3 0-17l19.8-19.8c4.7-4.7 12.3-4.7 17 0L160 282.7l119.7-118.5c4.7-4.7 12.3-4.7 17 0l19.8 19.8c4.7 4.7 4.7 12.3 0 17l-148 146.8c-4.7 4.7-12.3 4.7-17 0z" fill="currentColor"/></svg></button></Link>
                        </div>


                        <div className='b1l-in'>
                            <img src={fraud} alt=""/>
                            <Link to={"/the-use-of-ai-in-detecting-insurance-and-retail-financing-frauds/"} className='b1-link3'><h1 className="bmheading">The Use of AI in Detecting Insurance and Retail Financing Frauds</h1></Link>
                            <div className="b1-art-details">
                                <span><svg xmlns="http://www.w3.org/2000/svg" class="svg-icon" style={{width: "1em", height: "1em",verticalAlign: "middle",fill: "currentColor",overflow: "hidden"}} viewBox="0 0 1024 1024" version="1.1"><path d="M843.282963 870.115556c-8.438519-140.515556-104.296296-257.422222-233.908148-297.14963C687.881481 536.272593 742.4 456.533333 742.4 364.088889c0-127.241481-103.158519-230.4-230.4-230.4S281.6 236.847407 281.6 364.088889c0 92.444444 54.518519 172.183704 133.12 208.877037-129.611852 39.727407-225.46963 156.634074-233.908148 297.14963-0.663704 10.903704 7.964444 20.195556 18.962963 20.195556l0 0c9.955556 0 18.299259-7.774815 18.962963-17.73037C227.745185 718.506667 355.65037 596.385185 512 596.385185s284.254815 122.121481 293.357037 276.195556c0.568889 9.955556 8.912593 17.73037 18.962963 17.73037C835.318519 890.311111 843.946667 881.019259 843.282963 870.115556zM319.525926 364.088889c0-106.287407 86.186667-192.474074 192.474074-192.474074s192.474074 86.186667 192.474074 192.474074c0 106.287407-86.186667 192.474074-192.474074 192.474074S319.525926 470.376296 319.525926 364.088889z"/></svg> AIML Data</span>
                                <span style={{fontSize:"13px"}}><svg width="10" height="10" viewBox="0 0 190 190" xmlns="http://www.w3.org/2000/svg"><circle cx="100" cy="100" r="80" stroke="black" stroke-width="5" fill="white"/><line x1="100" y1="100" x2="100" y2="40" stroke="black" stroke-width="5" transform="rotate(150, 100, 100)" /><line x1="100" y1="100" x2="100" y2="30" stroke="black" stroke-width="5" transform="rotate(0, 100, 100)" /><circle cx="100" cy="100" r="2" fill="black" /></svg> July 4, 2022</span>
                                <span><svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 100 100"><rect x="10" y="20" width="80" height="50" rx="5" ry="5" fill="none" stroke="black" stroke-width="2" /><path d="M10 20 L50 20 L40 10 L10 10 Z" fill="none" stroke="black" stroke-width="2" /></svg> Uncategorized</span>
                                <span><svg xmlns="http://www.w3.org/2000/svg" class="svg-icon" style={{width: "1.142578125em",height: "1em",verticalAlign: "middle",fill: "currentColor",overflow: "hidden"}} viewBox="0 0 1170 1024" version="1.1"><path d="M841.134446 1024a36.790489 36.790489 0 0 1-17.590681-4.46167L490.052242 837.487579h-110.627465C173.74912 837.487579 0 656.095108 0 441.45954V373.035081A376.681947 376.681947 0 0 1 379.424777 0.01024h411.424457A376.681947 376.681947 0 0 1 1170.274012 373.035081v68.278174a369.367735 369.367735 0 0 1-292.568503 363.955218V987.428937a36.571063 36.571063 0 0 1-36.571063 36.571063zM379.424777 72.823226A303.539822 303.539822 0 0 0 73.142126 373.035081v68.278174c0 172.139993 143.13914 323.141911 306.282651 323.141912h119.989657a36.900202 36.900202 0 0 1 17.590682 4.46167L804.563383 926.099265v-150.233927a36.571063 36.571063 0 0 1 30.28084-35.839641C994.184344 712.26826 1097.131886 595.058004 1097.131886 441.45954V373.035081a303.539822 303.539822 0 0 0-306.282652-300.358139h-411.424457z"/></svg> 0 Comments</span>
                            </div>
                            <p>According to LegalJobs, insurance fraud costs American consumers $80 billion annually. Similarly, the retail industry experiences extensive fraud that leaves consumers and entrepreneurs frustrated. For many years, conventional solutions failed…</p>
                            <Link to={"/the-use-of-ai-in-detecting-insurance-and-retail-financing-frauds/"}><button className="bloglist-button">Continue Reading <svg style={{transform:"rotateZ(270deg)"}} width="13" height="10" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path d="M151.5 347.8L3.5 201c-4.7-4.7-4.7-12.3 0-17l19.8-19.8c4.7-4.7 12.3-4.7 17 0L160 282.7l119.7-118.5c4.7-4.7 12.3-4.7 17 0l19.8 19.8c4.7 4.7 4.7 12.3 0 17l-148 146.8c-4.7 4.7-12.3 4.7-17 0z" fill="currentColor"/></svg></button></Link>
                        </div>

                        <div className='b1l-in'>
                            <img src={hades} alt=""/>
                            <Link to={"/mister-fantastic-order-in-chaos-h-a-d-e-s/"} className='b1-link3'><h1 className="bmheading">Mister Fantastic: Order in chaos – H.A.D.E.S.</h1></Link>
                            <div className="b1-art-details">
                                <span><svg xmlns="http://www.w3.org/2000/svg" class="svg-icon" style={{width: "1em", height: "1em",verticalAlign: "middle",fill: "currentColor",overflow: "hidden"}} viewBox="0 0 1024 1024" version="1.1"><path d="M843.282963 870.115556c-8.438519-140.515556-104.296296-257.422222-233.908148-297.14963C687.881481 536.272593 742.4 456.533333 742.4 364.088889c0-127.241481-103.158519-230.4-230.4-230.4S281.6 236.847407 281.6 364.088889c0 92.444444 54.518519 172.183704 133.12 208.877037-129.611852 39.727407-225.46963 156.634074-233.908148 297.14963-0.663704 10.903704 7.964444 20.195556 18.962963 20.195556l0 0c9.955556 0 18.299259-7.774815 18.962963-17.73037C227.745185 718.506667 355.65037 596.385185 512 596.385185s284.254815 122.121481 293.357037 276.195556c0.568889 9.955556 8.912593 17.73037 18.962963 17.73037C835.318519 890.311111 843.946667 881.019259 843.282963 870.115556zM319.525926 364.088889c0-106.287407 86.186667-192.474074 192.474074-192.474074s192.474074 86.186667 192.474074 192.474074c0 106.287407-86.186667 192.474074-192.474074 192.474074S319.525926 470.376296 319.525926 364.088889z"/></svg> AIML Data</span>
                                <span style={{fontSize:"13px"}}><svg width="10" height="10" viewBox="0 0 190 190" xmlns="http://www.w3.org/2000/svg"><circle cx="100" cy="100" r="80" stroke="black" stroke-width="5" fill="white"/><line x1="100" y1="100" x2="100" y2="40" stroke="black" stroke-width="5" transform="rotate(150, 100, 100)" /><line x1="100" y1="100" x2="100" y2="30" stroke="black" stroke-width="5" transform="rotate(0, 100, 100)" /><circle cx="100" cy="100" r="2" fill="black" /></svg> July 4, 2022</span>
                                <span><svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 100 100"><rect x="10" y="20" width="80" height="50" rx="5" ry="5" fill="none" stroke="black" stroke-width="2" /><path d="M10 20 L50 20 L40 10 L10 10 Z" fill="none" stroke="black" stroke-width="2" /></svg> Uncategorized</span>
                                <span><svg xmlns="http://www.w3.org/2000/svg" class="svg-icon" style={{width: "1.142578125em",height: "1em",verticalAlign: "middle",fill: "currentColor",overflow: "hidden"}} viewBox="0 0 1170 1024" version="1.1"><path d="M841.134446 1024a36.790489 36.790489 0 0 1-17.590681-4.46167L490.052242 837.487579h-110.627465C173.74912 837.487579 0 656.095108 0 441.45954V373.035081A376.681947 376.681947 0 0 1 379.424777 0.01024h411.424457A376.681947 376.681947 0 0 1 1170.274012 373.035081v68.278174a369.367735 369.367735 0 0 1-292.568503 363.955218V987.428937a36.571063 36.571063 0 0 1-36.571063 36.571063zM379.424777 72.823226A303.539822 303.539822 0 0 0 73.142126 373.035081v68.278174c0 172.139993 143.13914 323.141911 306.282651 323.141912h119.989657a36.900202 36.900202 0 0 1 17.590682 4.46167L804.563383 926.099265v-150.233927a36.571063 36.571063 0 0 1 30.28084-35.839641C994.184344 712.26826 1097.131886 595.058004 1097.131886 441.45954V373.035081a303.539822 303.539822 0 0 0-306.282652-300.358139h-411.424457z"/></svg> 0 Comments</span>
                            </div>
                            <p>Victor, Dr. Victor Von Doom wanted to prove a point to this world. His cynical and sinister outlook led us to this point. He created a program called ‘The Purge’…</p>
                            <Link to={"/mister-fantastic-order-in-chaos-h-a-d-e-s/"}><button className="bloglist-button">Continue Reading <svg style={{transform:"rotateZ(270deg)"}} width="13" height="10" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path d="M151.5 347.8L3.5 201c-4.7-4.7-4.7-12.3 0-17l19.8-19.8c4.7-4.7 12.3-4.7 17 0L160 282.7l119.7-118.5c4.7-4.7 12.3-4.7 17 0l19.8 19.8c4.7 4.7 4.7 12.3 0 17l-148 146.8c-4.7 4.7-12.3 4.7-17 0z" fill="currentColor"/></svg></button></Link>
                        </div>

                    </div>

                    <div className="b1-right">
                        <h2>Recent Posts</h2>
                        <Link to={"/mister-fantastic-order-in-chaos-h-a-d-e-s/"} className="f1-link3">Mister Fantastic: Order in chaos – H.A.D.E.S.</Link>
                        <Link to={"/mister-fantastic-the-impending-doom/"} className="f1-link3">Mister Fantastic: The Impending Doom</Link>
                        <Link to={"/conversational-artificial-intelligence-overcoming-the-challenges/"} className="f1-link3">Conversational Artificial Intelligence – Overcoming the Challenges</Link>
                        <Link to={"/artificial-intelligence-in-cybersecurity/"} className="f1-link3">Artificial Intelligence in Cybersecurity</Link>
                        <Link to={"/the-role-of-ai-and-ml-in-edtech-and-personalized-learning/"} className="f1-link3">The Role of AI and ML in Edtech and Personalized Learning</Link>
                        <Link to={"/the-use-of-ai-in-detecting-insurance-and-retail-financing-frauds/"} className="f1-link3">The Use of AI in Detecting Insurance and Retail Financing Frauds</Link>
                    </div>

                </div>
            </>
        )
    }


    return(
    <>
    {loading?<Preloader/>:
    <>
        <Header/>
        <Spacer/>
        <BlogFeed/>
        <Footer/>
    </>
    }
    </>
    )
}

export default BlogList;