import React,{useEffect,useState} from "react";
import { Section8 } from "./Home";
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import './Blog.css';
import blog from "../Images/blog.png";
import { Link } from "react-router-dom";
import { Preloader } from "./About";

const Spacer = () =>{
    return(
        <>
            <div class="spacer">
                &nbsp;
            </div>
        </>
    )
}

const BlogSection1 = () => {
    return(
        <>
            <section className="blog-section1">
                <div className="blog-section1-data">
                    <div className="blog-section1-data-left">
                        <h1>AIML Data Blog</h1>
                    </div>
                </div>
                <div style={{textAlign:'center'}}>
                <img src={blog} alt="blog" style={{margin:'auto',width:"60%"}}/>
                </div>
            </section>
        </>
    )
}


const BlogSection2 = () =>{
    return(
        <>
        <div className="blog-section2">
            <p>Find all the latest news about AIML Data with expert opinions, product announcements, solutions and more.</p>
            <Link to={"/bloglist/"} style={{color:"white",textAlign:"center"}} target="_blank"><button className="a1-button">Our Blog</button></Link>
        </div>
        </>
    )
}

const Blog = () =>{

      const [loading,setLoading] = useState(true);

      useEffect(() => {
        document.title = "Blog - AIML Data"; // Set the title based on your logic
          setTimeout(() => {
              setLoading(false);
            }, 2000);
        }, []);
   
    return(
        <>
        {loading?<Preloader/>:
        <>
            <Header/>
            <Spacer/>
            <BlogSection1/>
            <BlogSection2/>
            <Section8/>
            <Footer/>
        </>
        }
        </>
    )
}


export default Blog;