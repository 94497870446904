import React,{useEffect,useState} from "react";
import { Section8 } from "./Home";
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import './Stories.css';
import { scrollToTop } from "./Home";
import { Link } from "react-router-dom";
import { Preloader } from "./About";

import TestimonialSlider from "../Components/TestimonialSlider";

const Spacer = () =>{
    return(
        <>
            <div class="spacer">
                &nbsp;
            </div>
        </>
    )
}

const StoriesSection1 = () => {
    return(
        <>
            <section className="story-section1">
                <div className="story-section1-data">
                    <div className="story-section1-data-left">
                    <h1>User Stories</h1>
                    <p>Read how we have delivered on our promise to our customers.</p>
                    <Link to={"/contact-us/"} onClick={scrollToTop}><button className="a1-button">Request a Demo</button></Link>
                    </div>
                </div>
            </section>
        </>
    )
}


const StoriesSection2 =() =>{
    return(
        <>
            <div className="story-section2">
                <div className="testimonial-screen">
                <TestimonialSlider/>
                </div>
            </div>
        </>
    )
}
const Stories = () =>{
    const [loading,setLoading] = useState(true)

    useEffect(() => {
        document.title = "AIML Data | User Stories | AI for Recruitment Process"; // Set the title based on your logic
        setTimeout(() => {
            setLoading(false);
          }, 2000);
      }, []);
      

    return(
        <>
        {loading?<Preloader/>:
        <>
            <Header/>
            <Spacer/>
            <StoriesSection1/>
            <StoriesSection2/>
            <Section8/>
            <Footer/>
        </>
        }
        </>
    )
}


export default Stories;