import "./App.css";
import { BrowserRouter,Route,Routes} from "react-router-dom";
import Home from "./Pages/Home";
import Contact from "./Pages/Contact";
import About from "./Pages/About";
import Solutions from "./Pages/Solutions";
import UseCases from "./Pages/UseCases";
import Blog from "./Pages/Blog";
import UserStories from "./Pages/Stories";
import BlogList from "./Pages/BlogList";
import Feed1 from "./Pages/Feed1";
import Feed2 from "./Pages/Feed2";
import Feed3 from "./Pages/Feed3";
import Feed4 from "./Pages/Feed4";
import Feed5 from "./Pages/Feed5";
import Feed6 from "./Pages/Feed6";
import NotFound from "./Pages/NotFound";

function App() {
  return (
    <>
    <BrowserRouter>
      <Routes>
        <Route exact path = "/" element={<Home/>}/>
        <Route exact path="/about-us/" element={<About/>}/>
        <Route exact path="/solutions/" element={<Solutions/>}/>
        <Route exact path="/use-cases/" element={<UseCases/>}/>
        <Route exact path="/user-stories/" element={<UserStories/>}/>
        <Route exact path="/blog/" element={<Blog/>}/>
        <Route exact path="/contact-us/" element={<Contact/>}/>
        <Route exact path="/bloglist" element={<BlogList/>}/>
        <Route exact path="/artificial-intelligence-in-cybersecurity/" element={<Feed1/>}/>
        <Route exact path="/mister-fantastic-order-in-chaos-h-a-d-e-s/" element={<Feed2/>}/>
        <Route exact path="/mister-fantastic-the-impending-doom/" element={<Feed3/>}/>
        <Route excat path="/conversational-artificial-intelligence-overcoming-the-challenges/" element={<Feed4/>}/>
        <Route exact path="/the-role-of-ai-and-ml-in-edtech-and-personalized-learning/" element={<Feed5/>}/>
        <Route exact path="/the-use-of-ai-in-detecting-insurance-and-retail-financing-frauds/" element={<Feed6/>}/>
        <Route exact path="*" element={<NotFound/>}/>

        

      </Routes>
    </BrowserRouter>
    </>
  );
}

export default App;
