import React, { useEffect } from "react";
import al_logo from '../Images/al_logo.png'
import './Header.css';
import { useState } from "react";
import { Link} from "react-router-dom";
import { scrollToTop } from "../Pages/Home";
import FAQ from '../PDF Files/AIML-FAQs.pdf';


const Header = () =>{

    const [hasSticky,sethasSticky] = useState(false);

    useEffect(() => {
        // Function to handle scroll event
        const handleScroll = () => {
          const scrollY = window.scrollY;
          // Define the scroll threshold where you want the shadow to appear
          const scrollThreshold = 150; // Adjust this value as needed
    
          // Check if the scroll position is greater than or equal to the threshold
          if (scrollY >= scrollThreshold) {
            sethasSticky(true);
          } else {
            sethasSticky(false);
          }
        };
    
        // Add scroll event listener when the component mounts
        window.addEventListener('scroll', handleScroll);
    
        // Clean up the event listener when the component unmounts
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
      }, []);
    return (
        <>
          <nav className={`${hasSticky?"is_sticky":""}`}>
            <input type="checkbox" id="nav-toggle"/>
            <div class="logo"><Link onClick={scrollToTop} to={"/"}><img src={al_logo} alt="Logo" /></Link></div>
            <ul class="links">
              <li><Link onClick={scrollToTop} to={"/about-us/"}>About</Link></li>
              <li><Link onClick={scrollToTop} to={"/solutions/"}>Solutions</Link></li>
              <li className="dropdown"><Link>Insights <svg class="icon" width="4" height="10" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path d="M151.5 347.8L3.5 201c-4.7-4.7-4.7-12.3 0-17l19.8-19.8c4.7-4.7 12.3-4.7 17 0L160 282.7l119.7-118.5c4.7-4.7 12.3-4.7 17 0l19.8 19.8c4.7 4.7 4.7 12.3 0 17l-148 146.8c-4.7 4.7-12.3 4.7-17 0z" fill="currentColor"/></svg></Link>
                <ul className="dropdown-nav">
                <li><Link onClick={scrollToTop} to={"/use-cases/"}>Case Studies</Link></li>
                <li><Link onClick={scrollToTop} to={"/user-stories/"}>User Stories</Link></li>
                </ul>
              </li>
              <li className="dropdown"><Link>Resources <svg class="icon" width="4" height="10" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path d="M151.5 347.8L3.5 201c-4.7-4.7-4.7-12.3 0-17l19.8-19.8c4.7-4.7 12.3-4.7 17 0L160 282.7l119.7-118.5c4.7-4.7 12.3-4.7 17 0l19.8 19.8c4.7 4.7 4.7 12.3 0 17l-148 146.8c-4.7 4.7-12.3 4.7-17 0z" fill="currentColor"/></svg></Link>
                <ul className="dropdown-nav">
                  <li><Link onClick={scrollToTop} to={"/blog/"}>Blog</Link></li>
                  <li><Link to={FAQ} target="_blank"  rel="noopener noreferrer">FAQ</Link></li>
                </ul>
              </li>
              <li><Link onClick={scrollToTop} to={"/contact-us/"}>Contact</Link></li>
              <Link onClick={scrollToTop} to={"/contact-us/"}><button className="demo-button">Request a demo</button></Link>
            </ul>
            <label for="nav-toggle" class="icon-burger">
                <div class="line"></div>
                <div class="line"></div>
                <div class="line"></div>
            </label>
          </nav>
        </>
        );
    
}

export default Header;