import React from "react";
import Header from '../Components/Header';
import Footer from "../Components/Footer";
import './Home.css';
import './About.css';
import ImageSlider from "../Components/ImageSlider";
import a1 from '../Images/a1.png';
import { useState,useRef,useEffect } from "react";
import { Link } from "react-router-dom";
import { scrollToTop } from "./Home";
import video from "../Videos/about.mp4";

export const Spacer = () =>{
    return(
        <>
            <div class="spacer">
                &nbsp;
            </div>
        </>
    )
}

const ShowVideo = () => {
    const [showVideo, setShowVideo] = useState(false);
  
    // Function to handle the video play and toggle the state
    const handlePlayVideo = () => {
      setShowVideo(true);
    };
  
    // Function to handle the video end and toggle the state
    const handleVideoEnd = () => {
      setShowVideo(false);
    };
  
    return (
      <>
        {showVideo ? (
          <div className="ab-container">
            <video
              src={video}
              onEnded={handleVideoEnd}
              autoPlay
              controls
              preload="metadata"
              controlsList="nodownload"
              poster="https://aimldata.com/wp-content/uploads/2022/06/about-video-banner.png"
              style={{ width: '100%', borderRadius: '30px' }}
            ></video>
          </div>
        ) : (
          <>
            <div className="ab-container">
              <img src={a1} alt="A1" style={{ maxWidth: '80%', height: 'auto', display: 'block', margin: 'auto', borderRadius: '30px' }} />
              <div className="yt-button" onClick={handlePlayVideo}></div>
            </div>
          </>
        )}
      </>
    );
  };
  

const SectionA1 = () =>{
    return(
        <>
            <section className="a1">
            <div className="aom">
                <h1>About Us</h1>
                <p>Our mission is to accelerate data driven decision making using AI and ML as a catalysts. At AIML Data, we endeavor, to simplify business processes and enhance user experience by using light weight, autonomous and flexible AI driven data labeling models.</p>
                <Link onClick={scrollToTop} to={"/contact-us/"}><button className="a1-button">Request a Demo</button></Link>
                <ShowVideo/>
            </div>
            </section>
        </>

    )
}


const SectionA2 = () =>{
    return(
        <>  
            <section className='a2'>
                <h1>Every Day is a New Day, a New Win!</h1>
                <p>We are a 200-member family, which is a mix of serial entrepreneurs, data scientists, process auditors, BFSI (Banking and Finance) and O&G (Oil & Gas) experts, CFA / CA, Engineers, programming experts and app developers.</p>
                <p>Our blend of offerings equipped with a cutting-edge modular AI platform, accelerate the process of managing training data, data labeling and  insight building. Our offering is agnostic of width-depth-spread of the organization as well as the IT infrastructure of the organization.</p>
                <p>We help our partners manage data BETTER-FASTER-RELIABLE with limited uptick in operational cost. Our unique proposition help organizations spend more time exploring “What next” rather than “What is this” as well as driving the business decisions democratically with increased agility and reliability.</p>


                <h1>Our Culture and Commitment</h1>
                <p>At AIML Data, we make courageous decisions and collaborate with honesty, professionalism, diligence and perseverance. Our culture is based on integrity and respect towards co-workers, clients and work deadlines.</p>
                <p>We partner with world’s leading technology institutes to foster a culture of innovation and entrepreneurship.</p>
                <p>We are committed to positively impacting the society we live in by nurturing a sustainable ecosystem amongst our stakeholders – customers, employees and shareholders – through our technology, solutions and expertise.</p>
                <p>We believe that diverse, inclusive teams drive high performance and executable ideation, creating better business value for our customers.</p>
            </section>
        </>
    )
}

export const Section8 = () =>{
    return(
        <section className="s-8">
            <div className="sales">
                <div className="sales-left">
                    <h1>Talk To Sales</h1>
                    <p>Our expert team is happy to answer questions about your business problem and how we can use machine learning, AI, data labelling and data processing to solve it.</p>
                    <div className="sales-email">
                        <Link to={"mailto:sales@aimldata.com"} style={{ display: "flex", alignItems: "center", textDecoration: "none" ,justifyContent:"center"}}>
                            <svg width="20px" height="20px" viewBox="0 0 469.2 469.2" xmlns="http://www.w3.org/2000/svg">
                                <g>
                                    <path d="M22.202,77.023C25.888,75.657,29.832,74.8,34,74.8h401.2c4.168,0,8.112,0.857,11.798,2.224L267.24,246.364
                                        c-18.299,17.251-46.975,17.251-65.28,0L22.202,77.023z M464.188,377.944c3.114-5.135,5.012-11.098,5.012-17.544V108.8
                                        c0-4.569-0.932-8.915-2.57-12.899L298.411,254.367L464.188,377.944z M283.2,268.464c-13.961,11.961-31.253,18.027-48.6,18.027
                                        c-17.347,0-34.64-6.06-48.6-18.027L20.692,391.687c4.094,1.741,8.582,2.714,13.308,2.714h401.2c4.726,0,9.214-0.973,13.308-2.714
                                        L283.2,268.464z M2.571,95.9C0.932,99.885,0,104.23,0,108.8V360.4c0,6.446,1.897,12.409,5.012,17.544l165.777-123.577L2.571,95.9z"
                                        fill="#FFFFFF" stroke="#000000" />
                                </g>
                            </svg>
                            <h2 style={{ marginLeft: "10px", fontSize: "16px", color: "#fff",fontFamily:"SofiaPro-Light"}}>sales@aimldata.com</h2>
                        </Link>
                    </div>
                </div>
                <div className="sales-right">
                <form action="#" method="post">
                    <input type="text" id="fullName" name="fullName" placeholder="Full Name" required/>

                    <input type="email" id="email" name="email" placeholder="Email" required/>

                    <input type="text" id="jobTitle" name="jobTitle" placeholder="Job Title" required/>

                    <input type="tel" id="phone" name="phone" placeholder="Phone Number" required/>

                    <button className="s1-button">Submit</button>
                </form>
                </div>
            </div>
        </section>
    )
}

export const Preloader = () =>{
    return(
      <>
        <div class="loader"></div>
      </>
    )
  } 
  
const About = () =>{
    const [loading,setLoading] = useState(true)

    useEffect(() => {
        document.title = "Data Driven Decision Making | AI & Machine Learning | AIML Data"; // Set the title based on your logic
        setTimeout(() => {
            setLoading(false);
          }, 2000);
      }, []);


    return(
        <>
        {loading?<Preloader/>:
        <>
            <Header/>
            <Spacer/>
            <SectionA1/>
            <SectionA2/>
            <ImageSlider/>
            <div className="dot-square-blue" style={{height:'100px',width:'100px',position:'absolute',right:'0',top:'316%'}}></div>
            <div style={{margin:'9rem'}}></div>
            <Section8/>
            <Footer/>
        </>
        }
        </>
    )
}

export default About;