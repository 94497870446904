// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import React,{useState} from "react";
import Slider from "react-slick";
import slide_pic from '../Images/slider-pic.png';
import "./Slider.css";


const items = [
    {
      image:slide_pic,
      paragraph:"AIML Data are experts in process management and have a world-class quality assurance team. We can now focus our energy more on our models without worrying on the quality of annotations.",
      sentence:'Steve Farris ',
      sub_sentence:'Vice President, US Oil and Gas',
    },
    {
      image:slide_pic,
      paragraph:"Working with AIML Data has been a great experience. They quickly learnt our line of business, adapted to our requirements and have consistently performed well. They are reliable and a wonderful partner.",
      sentence:'Christopher Williams',
      sub_sentence:'Executive Director, Global Renewables',
    },
    {
      image:slide_pic,
      paragraph:"After speaking to 10-15 different companies, we decided to go with AIML Data as they understood our problem much better than others. They provided valuable insight, technologies and capabilities in handling our requirements which included processing millions of data points.",
      sentence:'Richard Hardman',
      sub_sentence:'Program Manager, US Bank',
    },
    {
      image:slide_pic,
      paragraph:"AIML Data team was extremely thoughtful and had a holistic approach towards the problem which provided us with unique insights from the data to which we were previously oblivious to.They effectively improved the quality using machine learning techniques.",
      sentence:'Felipe Vasquez',
      sub_sentence:'Director of Data Ingestion, US Financial Service',
    },
  // Add more slide items as needed
  ];

  const TCard = ({image,paragraph,sentence,sub_sentence}) =>{
    return(
        <>
            <div className="carousel-item">
                <div id="signatory">
                    <img src={image} alt='VVVVV'/>
                    <p>{paragraph}</p>
                    <h1>{sentence}</h1>
                    <h2>{sub_sentence}</h2>
                </div>
          </div>
        </>
    )
  }
  

  const SimpleSlider = () => {
    const [sliderRef, setSliderRef] = useState(null)
    const settings = {
        dots: false,
        infinite: true,
        speed:1000,
        
        slidesToShow: 3,
        slidesToScroll: 1,
        arrows:false,
        responsive: [
            {
                breakpoint: 1300,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    dots:true,
                }
            },
            {
                breakpoint: 799,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots:true,
                }
            }
        ]
    };
    

  return (
    <>
      <div class="b-rectangle" >
          <button class="s-button" id="bl" onClick={sliderRef?.slickPrev}>
              <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0.280586 8.76296L6.28076 15.5662C6.77717 16.0851 7.57315 16.1347 8.09567 15.7352C8.61814 15.3358 8.70424 14.5283 8.27635 14.0405L4.00393 9.20067H16.7142C17.4243 9.20067 18 8.66316 18 8.00012C18 7.33704 17.4243 6.79954 16.7142 6.79954H4.00393L8.27635 1.95967C8.70424 1.47188 8.60794 0.675807 8.09567 0.265008C7.56741 -0.158636 6.70851 -0.0539782 6.28076 0.433937L0.280586 7.23722C-0.110014 7.75007 -0.0766714 8.26704 0.280586 8.76296Z" fill="#584997"></path>
              </svg>
          </button>
          <button class="s-button" id="br" onClick={sliderRef?.slickNext}>
              <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M17.7194 8.76296L11.7192 15.5662C11.2228 16.0851 10.4268 16.1347 9.90433 15.7352C9.38186 15.3358 9.29576 14.5283 9.72365 14.0405L13.9961 9.20067H1.28576C0.575657 9.20067 0 8.66316 0 8.00012C0 7.33704 0.575657 6.79954 1.28576 6.79954H13.9961L9.72365 1.95967C9.29576 1.47188 9.39206 0.675807 9.90433 0.265008C10.4326 -0.158636 11.2915 -0.0539782 11.7192 0.433937L17.7194 7.23722C18.11 7.75007 18.0767 8.26704 17.7194 8.76296Z" fill="#584997"></path>
              </svg>
          </button>
      </div>

      <div class="slide-out">
        <Slider ref={setSliderRef} {...settings}>
        {items.map((item, index) => (
              <div key={index}>
                <TCard 
                image={item.image}
                paragraph={item.paragraph}
                sentence={item.sentence}
                sub_sentence={item.sub_sentence}
                />
              </div>
            ))}
        </Slider>
      </div>
    </>
  );
}

export default SimpleSlider;