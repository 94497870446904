import React, { useState,useEffect } from 'react';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import "./Slider.css";
import './ImageSlider.css'; // Create a CSS file for styling
import is1 from '../Images/is1.png';
import is2 from '../Images/is2.png';
import is3 from '../Images/is3.png';
import is4 from '../Images/is4.png';
import is5 from '../Images/is5.png';
import is6 from '../Images/is6.png';



  const items = [
    {
      image:is1,
    },
    {
      image:is2,
    },
    {
      image:is3,
    },
    {
      image:is4,
    },
    {
    image:is5,
    },
    {
    image:is6,
    },
      
  // Add more slide items as needed
];

const TCard = ({image,}) =>{
  return(
      <>
        <div className="image-slide">
          <img src={image} alt='VVVVV'/>
        </div>
      </>
  )
}



const ImageSlider = () => {
  const [imageRef, setImageRef] = useState(null)
  const settings = {
      dots: true,
      infinite: true,
      autoplaySpeed:3000,
      slidesToShow: 3,
      slidesToScroll: 1,
      autoplay:true,
      arrows:false,
      responsive: [
          {
              breakpoint: 1300,
              settings: {
                  slidesToShow: 2,
                  slidesToScroll: 1,
                  dots:true,
              }
          },
          {
              breakpoint: 799,
              settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1,
                  dots:true,
              }
          }
      ]
  };
  

  return (
    <>
      <div class="image-out">
        <Slider ref={setImageRef} {...settings}>
        {items.map((item, index) => (
              <div key={index}>
                <TCard 
                image={item.image}
                paragraph={item.paragraph}
                sentence={item.sentence}
                sub_sentence={item.sub_sentence}
                />
              </div>
            ))}
        </Slider>
      </div>
    </>
  );
}


export default ImageSlider;